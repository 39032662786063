import React, { useEffect, useState } from "react";
import axios from "axios";
import { notify } from "../Toast/Toast";
import { useWebSocket } from "../../context/WebSocketProvider";
import DashboardCasino from "./DashboardMenu";

const CasinoCryptocoins = () => {
  const [coins, setCoins] = useState([]);
  const { sendMessage, colors } = useWebSocket();

  useEffect(() => {
    const getCoins = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/crypto/cryptocoins`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setCoins(response.data);
      } catch (error) {
        console.error("Error fetching coins:", error);
        notify("Failed to load coins", "error");
      }
    };
    getCoins();
  }, []);

  const handleActiveChange = async (coin) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/crypto/update/${coin._id}`,
        { active: !coin.active },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setCoins(coins.map((b) => (b._id === coin._id ? response.data : b)));
      sendMessage({ type: "ActiveCoins" });
    } catch (error) {
      console.error("Error updating coin:", error);
      notify("Failed to update coin", "error");
    }
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r = Math.max(0, Math.min(255, r));
      let g = (decimalColor & 0x0000ff) + magnitude;
      g = Math.max(0, Math.min(255, g));
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b = Math.max(0, Math.min(255, b));
      return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div
      className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG
      }}

    >
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4">
        <h1 className="pb-5 text-4xl text-left font-bold text-white">Coins</h1>
        <div
          className="p-4 rounded-lg shadow-md text-center gap-x-2 items-center flex-1 overflow-y-auto"
          style={{
            backgroundColor: newShade(colors?.secondaryColorBG, 10),
            border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-7 gap-y-1 flex-1 w-full">
            {coins.map((coin) => (
              <div
                key={coin._id}
                className="rounded-lg shadow-md p-4 m-2 text-center justify-between text-white"
                style={{
                  backgroundColor: newShade(colors?.secondaryColorBG, 30),
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                }}
              >
                <div className="flex items-center justify-center gap-x-2">
                  <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin.id}.png`} alt={`${coin.name}`} className="w-6 h-6 rounded-full" /><p className="font-semibold">{coin.name}</p>
                </div>
                <p className="flex justify-between text-sm">
                  <span>Position:</span>
                  {coin.pos}
                </p>
                <p className="flex justify-between text-sm">
                  <span>Sym:</span>
                  {coin.sym}
                </p>
                <p className="flex justify-between text-sm">
                  <span>Sym B:</span>
                  {coin.symb}
                </p>
                <p className="flex justify-between text-sm">
                  <span>Symb CB:</span>
                  {coin.symbCoinbase}
                </p>
                <p className="flex justify-between text-sm">
                  <span>ID:</span>
                  {coin.id}
                </p>
                <label className="flex items-center justify-center cursor-pointer select-none">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={coin.active}
                      onChange={() => handleActiveChange(coin)}
                    />
                    <div
                      className={`block ${coin.active ? "bg-green-500" : "bg-[#222636]"
                        } w-14 h-8 rounded-full`}
                      style={{
                        backgroundColor: coin.active
                          ? colors?.primaryColor
                          : colors?.mainColorBG,
                      }}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-white transition-transform ${coin.active
                        ? "transform translate-x-full"
                        : "transform translate-x-0"
                        }`}
                    ></div>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoCryptocoins;
