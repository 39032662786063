import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaSearch, FaEquals, } from "react-icons/fa";
import { MdOutlineTune } from "react-icons/md";
import { useWebSocket } from "../../context/WebSocketProvider";
import cryptoConfig from "../../cryptoConfig.js";

function LeftSideBar({ coin }) {
  const { cryptoData, cryptoInfo, colors } = useWebSocket();
  const navigate = useNavigate();
  const [cryptoList, setCryptoList] = useState([]);
  const [arrowDirections, setArrowDirections] = useState({});
  const [filteredCryptoList, setFilteredCryptoList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  // Fetch initial crypto info
  useEffect(() => {
    const getCryptoInfo = async () => {
      if (Array.isArray(cryptoInfo)) {
        setCryptoList(
          cryptoInfo.map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else if (typeof cryptoInfo === "object") {
        setCryptoList(
          Object.values(cryptoInfo).map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else {
        console.error("Unexpected data format:", cryptoInfo);
      }
    };

    getCryptoInfo();
  }, [cryptoInfo]);

  // Update cryptoList with prices from cryptoData when cryptoData changes
  useEffect(() => {
    if (cryptoData.data) {
      setCryptoList((prevCryptoList) =>
        prevCryptoList.map((crypto) => {
          const cryptoName = crypto.symbol + "USDT";
          const newPrice = cryptoData.data[cryptoName] || null;

          return {
            ...crypto,
            price: newPrice,
            previousPrice: crypto.price,
            cryptoName: cryptoName,
          };
        })
      );
    }
  }, [cryptoData]);

  // Function to determine arrow direction based on price change
  const getArrowDirection = (crypto, index) => {
    if (crypto.price && crypto.previousPrice) {
      if (crypto.price > crypto.previousPrice) {
        return (
          <FaAngleDoubleUp
            className="w-3 h-[13px]"
            style={{ color: colors?.profitColor }}
          />
        );
      } else if (crypto.price < crypto.previousPrice) {
        return (
          <FaAngleDoubleDown
            className="w-3 h-[13px]"
            style={{ color: colors?.lossColor }}
          />
        );
      } else {
        return (
          arrowDirections[index] || (
            <FaEquals className="w-3 h-[13px] text-gray-400" />
          )
        );
      }
    } else {
      return <FaEquals className="w-3 h-[13px] text-gray-400" />;
    }
  };

  // Format numeric values for display
  const getFormattedValue = (value, symbol) => {
    if (value !== null) {
      const precision = cryptoConfig[symbol]?.precision || 2;
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(value);
    }
    return "-";
  };

  // Update arrow directions in state
  useEffect(() => {
    if (cryptoList.length > 0) {
      const updatedDirections = {};
      cryptoList.forEach((crypto, index) => {
        if (crypto.price !== null && crypto.previousPrice !== null) {
          if (crypto.price > crypto.previousPrice) {
            updatedDirections[index] = (
              <FaAngleDoubleUp
                className="w-3 h-[13px]"
                style={{ color: colors?.profitColor }}
              />
            );
          } else if (crypto.price < crypto.previousPrice) {
            updatedDirections[index] = (
              <FaAngleDoubleDown
                className="w-3 h-[13px] text-[#FF4949]"
                style={{ color: colors?.lossColor }}
              />
            );
          } else {
            updatedDirections[index] = arrowDirections[index] || (
              <FaEquals className="w-3 h-[13px] text-gray-400" />
            );
          }
        }
      });
      setArrowDirections((prevDirections) => ({
        ...prevDirections,
        ...updatedDirections,
      }));
    }
  }, [cryptoList]);

  // Filter cryptoList based on searchQuery
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCryptoList([]);
    } else {
      const filteredList = cryptoList.filter((crypto) =>
        crypto.symbol.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCryptoList(filteredList);
    }
  }, [cryptoList, searchQuery]);

  // Handle search input change
  const handleSearch = (e) => {
    const searchValue = e.target.value.trim();
    setSearchQuery(searchValue);
  };

  const handleCryptopick = (cryptoName, cryptoSymbol) => {
    if (coin === cryptoName) {
      return; // Do nothing if the same crypto is clicked
    }
    navigate(`/${cryptoSymbol}`);
  };

  return (
    <div
      className="hidden xl:flex flex-col h-full text-[#B1B6C6] overflow-auto scrollbar"
      style={{
        backgroundColor: colors?.secondaryColorBG,
      }}
    >
      <div className="flex items-center w-full p-4 px-8 pl-[30px] pt-6 text-base">
        <span className="flex-1 text-base font-medium text-left text-white whitespace-nowrap">
          Assets
        </span>
        <button className="hover:text-[#294598] text-white">
          <MdOutlineTune className="ml-2 size-6" />
        </button>
      </div>
      <div className="relative p-2 px-7 pl-[30px]">
        <div className="absolute inset-y-0 flex items-center pointer-events-none start-8 ps-3">
          <FaSearch className="w-3 h-3" />
        </div>
        <input
          type="search"
          id="search"
          className="block w-full p-2 text-sm rounded-md ps-10 focus-visible:outline-none"
          placeholder="search..."
          onChange={handleSearch}
          style={{
            backgroundColor: colors?.mainColorBG,
          }}
        />
      </div>
      <ul className="py-2">
        {(filteredCryptoList.length > 0 ? filteredCryptoList : cryptoList)
          .filter((crypto) => crypto.price !== null) // Only show cryptos with a valid price
          .map((crypto, index) =>
            crypto.active ? (
              <li
                key={crypto.id}
                onClick={() =>
                  handleCryptopick(crypto.cryptoName, crypto.symbol)
                }
                className="flex px-6 items-center h-9 cursor-pointer"
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = colors?.mainColorBG;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = colors?.secondaryColorBG;
                }}
              >
                <img
                  src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`}
                  alt={crypto.symbol}
                  className="w-5 h-5 mx-2 rounded-full"
                />
                <div className="flex items-center justify-between w-full p-1 text-xs">
                  <span
                    className={`${coin === `${crypto.cryptoName}`
                      ? "transition-all duration-100 text-[#ffffc1] text-[13px]"
                      : ""
                      }`}
                    style={{
                      color:
                        coin === crypto.cryptoName ? colors?.profitColor : "",
                      textShadow:
                        coin === crypto.cryptoName
                          ? `0px 0px 8px ${colors?.profitColor}`
                          : "",
                    }}
                  >
                    {crypto.symbol}
                  </span>
                  <span className="flex items-center text-[13px] text-white">
                    {getFormattedValue(crypto.price, crypto.cryptoName)}
                    <span className="ml-3">
                      {getArrowDirection(crypto, index)}
                    </span>
                  </span>
                </div>
              </li>
            ) : null
          )}
      </ul>
    </div>
  );
}

export default LeftSideBar;
