import React, { useState } from 'react';
import { FaCaretDown } from "react-icons/fa";
import { useTimerangepick } from '../../context/timerangepick.jsx';
import { useWebSocket } from '../../context/WebSocketProvider';

const TimeRangeSelector = ({ isOpen, toggleOpen }) => {
    const { timerangepick, setTimerangepick } = useTimerangepick();
    const { colors } = useWebSocket();

    /*  const handleClick = () => {
         setIsOpen(!isOpen);
     }; */

    const handleChange = (value) => {
        setTimerangepick(value);
        toggleOpen();  // Close after selecting
    };

    const options = [
        { value: 'tick', label: 'Tick' },
        { value: 900000, label: '5s' },
        { value: 1800000, label: '15s' },
        { value: 3600000, label: '30s' },
        { value: 7200000, label: '5m' },
        { value: 14400000, label: '15m' },
        { value: 43200000, label: '30m' },
        { value: 129600000, label: '1h' },
        { value: 388800000, label: '4h' },
        { value: 1166400000, label: '12h' }
    ];

    const selectedOption = options.find(option => option.value === timerangepick);

    return (
        <div className="relative">
            <button onClick={toggleOpen} className="flex flex-row items-center p-2 px-3 rounded-md gap-x-1 hover:brightness-125" style={{
                backgroundColor: colors?.secondaryColorBG,
            }}>
                <p className='text-[13px]'>{selectedOption ? selectedOption.label : 'Select Time Range'}</p>
                <FaCaretDown />
            </button>
            {isOpen && (
                <div className="absolute left-0 right-0 z-10 overflow-y-auto text-white rounded shadow-lg top-12 scroll max-h-[200px] lg:max-h-none" style={{
                    backgroundColor: colors?.secondaryColorBG,
                }}>
                    {options.map(option => (
                        <div
                            key={option.value}
                            className="px-4 py-2 text-xs cursor-pointer hover:brightness-125"
                            style={{
                                backgroundColor: colors?.secondaryColorBG,
                            }}
                            onClick={() => handleChange(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TimeRangeSelector;
