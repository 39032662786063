import React, { useState, useEffect, useRef } from "react";
import authService from "../services/auth.service";

const AuthContext = React.createContext();

function AuthProviderWrapper(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const autoLoginTimerRef = useRef(null);

  const storeToken = (token) => {
    localStorage.setItem("authToken", token);
  };

  const authenticateUser = () => {
    // Return a promise to handle the authentication process
    return new Promise((resolve, reject) => {
      const storedToken = localStorage.getItem("authToken");

      if (storedToken) {
        authService
          .verify()
          .then((response) => {
            const user = response.data;
            setIsLoggedIn(true);
            setIsLoading(false);
            setUser(user);
            resolve(user); // Resolve with the user data
          })
          .catch((error) => {
            setIsLoggedIn(false);
            setIsLoading(false);
            setUser(null);
            resolve(null); // Resolve with null if no token
          });
      } else {
        setIsLoggedIn(false);
        setIsLoading(false);
        setUser(null);
        resolve(null); // Resolve with null if no token
      }
    });
  };

  const removeToken = () => {
    localStorage.removeItem("authToken");
  };

  const logOutUser = () => {
    // Upon logout, remove the token from the localStorage
    removeToken();
    authenticateUser();

    /*     // Disable auto-login and set logout timestamp
        setAutoLoginFlag(false);
        setLogoutTimestamp();
    
        // Start a timer to re-enable auto-login after 5 minutes
        if (autoLoginTimerRef.current === null) {
          autoLoginTimerRef.current = setTimeout(() => {
            resetAutoLoginFlag();
          }, AUTO_LOGIN_TIMEOUT);
        } */
    window.location.reload();
  };

  useEffect(() => {
    // Run this code once the AuthProviderWrapper component in the App loads for the first time.
    // This effect runs when the application and the AuthProviderWrapper component load for the first time.
    authenticateUser();
  }, []);


  // Auto Login for demo purposes

  /* const ENABLE_AUTO_LOGIN = true;
  const LOGOUT_TIMESTAMP_KEY = "LOGOUT_TIMESTAMP";
  const AUTO_LOGIN_KEY = "AUTO_LOGIN_ENABLED";
  const AUTO_LOGIN_TIMEOUT = 5 * 60 * 1000;
  const AUTO_SIGNUP_EMAIL_KEY = "AUTO_SIGNUP_EMAIL";
  const TEST_USERNAME = "User";
  const TEST_PASSWORD = "User.12";

  // Helper Functions
  // Set the auto-login flag
  const setAutoLoginFlag = (value) => {
    localStorage.setItem(AUTO_LOGIN_KEY, value ? "true" : "false");
  };

  // Get the current auto-login flag
  const getAutoLoginFlag = () => {
    const flag = localStorage.getItem(AUTO_LOGIN_KEY);
    return flag === "false" ? false : true; // Default to true if not set
  };

  // Reset the auto-login flag to true
  const resetAutoLoginFlag = () => {
    setAutoLoginFlag(true);
    localStorage.removeItem(LOGOUT_TIMESTAMP_KEY);
    autoLoginTimerRef.current = null;
  };

  // Set the logout timestamp
  const setLogoutTimestamp = () => {
    const timestamp = new Date().getTime();
    localStorage.setItem(LOGOUT_TIMESTAMP_KEY, timestamp.toString());
  };

  // Get the logout timestamp
  const getLogoutTimestamp = () => {
    const timestamp = localStorage.getItem(LOGOUT_TIMESTAMP_KEY);
    return timestamp ? parseInt(timestamp, 10) : null;
  };

  const getAutoSignupEmail = () => {
    return localStorage.getItem(AUTO_SIGNUP_EMAIL_KEY);
  };

  const setAutoSignupEmail = (email) => {
    localStorage.setItem(AUTO_SIGNUP_EMAIL_KEY, email);
  };

  const generateRandomEmail = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generates a number between 1000 and 9999
    return `user${randomNumber}@gmail.com`;
  };

  // Initialize Auto-Login Flag on First Load
  useEffect(() => {
    if (localStorage.getItem(AUTO_LOGIN_KEY) === null) {
      setAutoLoginFlag(true);
    }
  }, []);

  useEffect(() => {
    const performAutoLogin = async () => {
      const autoLoginEnabled = getAutoLoginFlag();
      const logoutTimestamp = getLogoutTimestamp();
      const currentTime = new Date().getTime();

      if (logoutTimestamp) {
        const elapsedTime = currentTime - logoutTimestamp;
        if (elapsedTime >= AUTO_LOGIN_TIMEOUT) {
          // Reset the auto-login flag since the cooldown has passed
          resetAutoLoginFlag();
        } else {
          // Calculate remaining time and set a timer to reset the flag
          const remainingTime = AUTO_LOGIN_TIMEOUT - elapsedTime;
          if (autoLoginTimerRef.current === null) {
            autoLoginTimerRef.current = setTimeout(() => {
              resetAutoLoginFlag();
            }, remainingTime);
          }
          return; // Exit early to prevent auto-login
        }
      }

      if (
        ENABLE_AUTO_LOGIN &&
        autoLoginEnabled &&
        !isLoggedIn && // Only if not already logged in
        !isLoading // Only after initial auth check is complete
      ) {
        let email = getAutoSignupEmail();
        let isNewlyGenerated = false;

        if (!email) {
          // Generate a new email if not already stored
          email = generateRandomEmail();
          setAutoSignupEmail(email);
          isNewlyGenerated = true; // Flag to indicate that signup is needed
        }

        if (email && TEST_PASSWORD) {
          if (isNewlyGenerated) {
            try {
              // Attempt to auto-signup the test user
              await authService.signup({
                emailReg: email,
                passwordReg: TEST_PASSWORD,
                usernameReg: TEST_USERNAME, // Fixed username
              });
            } catch (signupError) {
              if (
                signupError.response &&
                signupError.response.status === 409
              ) {
                // 409 Conflict typically indicates that the user already exists
                console.warn(
                  "Test user already exists. Proceeding to auto-login."
                );
              } else {
                // Handle other signup errors if necessary
                console.error("Auto-signup failed:", signupError);
                return; // Exit if signup failed for reasons other than user already exists
              }
            }
          }

          try {
            // Proceed to auto-login
            const response = await authService.login({
              email,
              password: TEST_PASSWORD,
            });
            storeToken(response.data.authToken);
            await authenticateUser();
          } catch (loginError) {
            console.error("Auto-login failed:", loginError);
          }
        } else {
          console.warn(
            "Auto-login is enabled but test credentials are missing."
          );
        }
      }
    };

    performAutoLogin();
    // Dependencies: isLoggedIn, isLoading
  }, [isLoggedIn, isLoading]);

  useEffect(() => {
    // Cleanup on unmount to prevent memory leaks
    return () => {
      if (autoLoginTimerRef.current) {
        clearTimeout(autoLoginTimerRef.current);
      }
    };
  }, []); */

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        user,
        storeToken,
        authenticateUser,
        logOutUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProviderWrapper, AuthContext };
