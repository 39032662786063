import React, { useEffect, useState } from "react";
import DashboardCasino from "./DashboardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoUsers = () => {
  const [users, setUsers] = useState([]);
  const { colors } = useWebSocket();

  const calculateTotalAmount = (bets) => {
    return bets?.reduce((total, bet) => total + bet.amount, 0) || 0;
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/user/getAll`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getUsers();
  }, []);

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r = Math.max(0, Math.min(255, r));
      let g = (decimalColor & 0x0000ff) + magnitude;
      g = Math.max(0, Math.min(255, g));
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b = Math.max(0, Math.min(255, b));
      return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div
      className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}
    >
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4">
        <h1 className="pb-5 text-4xl text-left font-bold text-white">Users</h1>
        <div
          className="p-4 rounded-lg shadow-md text-center gap-x-2 items-center flex-1 overflow-y-auto"
          style={{
            backgroundColor: newShade(colors?.secondaryColorBG, 10),
            border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
          }}
        >
          <div
            className="grid grid-cols-3 rounded-lg shadow-md px-4 p-1 m-2 text-white"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
            }}>
            <div className="py-2 font-semibold">Name</div>
            <div className="py-2 font-semibold">Total Bets</div>
            <div className="py-2 font-semibold">Total Amount</div>
          </div>
          <div>
            {users.map((user) => (
              <div key={user?.id} className="grid grid-cols-3 rounded-lg shadow-md p-4 m-2 text-white"
                style={{
                  backgroundColor: newShade(colors?.secondaryColorBG, 30),
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                }}>
                <div className="py-2">{user.username}</div>
                <div className="py-2">{user.bets?.length}</div>
                <div className="py-2">
                  $ {calculateTotalAmount(user.bets).toFixed(2)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoUsers;
