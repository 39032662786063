import React, { useEffect, useState } from "react";
import DashboardCasino from "./DashboardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoBets = () => {
  const [bets, setBets] = useState([]);
  const { colors } = useWebSocket();

  useEffect(() => {
    const fetchUsersAndBets = async () => {
      try {
        // Fetch Users
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/user/getAll`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        const fetchedUsers = usersResponse.data;

        // Extract User IDs
        const userIds = new Set(fetchedUsers.map(user => user.id)); // Adjust `id` if your user ID field is different

        // Fetch Bets
        const betsResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/bet/getAll`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        // Filter Bets to Only Include Those Associated with Fetched Users
        const filteredBets = betsResponse.data
          .filter(bet => userIds.has(bet.userId)) // Adjust `userId` if your bet's user reference field is different
          .slice(0, 2500); // Limit to first 2500 bets if necessary

        setBets(filteredBets);
      } catch (error) {
        console.error("Error fetching users or bets:", error);
      }
    };

    fetchUsersAndBets();
  }, []); // Empty dependency array ensures this runs once on mount

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r = Math.max(0, Math.min(255, r));
      let g = (decimalColor & 0x0000ff) + magnitude;
      g = Math.max(0, Math.min(255, g));
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b = Math.max(0, Math.min(255, b));
      return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
    } else {
      return hexColor;
    }
  };




  return (
    <div className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4">
        <h1 className="pb-5 text-4xl text-left font-bold text-white">Bets</h1>
        <div
          className="p-4 rounded-lg shadow-md text-center gap-x-2 items-center flex-1 overflow-y-auto"
          style={{
            backgroundColor: newShade(colors?.secondaryColorBG, 10),
            border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
          }}
        >
          <div
            className="grid grid-cols-4 rounded-lg shadow-md px-4 p-1 m-2 text-white"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 20),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
            }}>
            <div className="py-2 font-semibold">User</div>
            <div className="py-2 font-semibold">Amount</div>
            <div className="py-2 font-semibold">Result</div>
            <div className="py-2 font-semibold">Profit/Loss</div>
          </div>
          <div>
            {bets.map((bet) => (
              <tr key={bet?.id} className="grid grid-cols-4 rounded-lg shadow-md p-4 m-2 text-white"
                style={{
                  backgroundColor: newShade(colors?.secondaryColorBG, 30),
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                }}>
                <td className="py-2">{bet.username}</td>
                <td className="py-2">$ {bet.amount}</td>
                <td className="py-2">{bet.result}</td>
                <td className="py-2">
                  {bet.profitOrLoss !== null && bet.profitOrLoss !== undefined
                    ? "$ " + bet.profitOrLoss
                    : "-"}
                </td>
              </tr>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoBets;
