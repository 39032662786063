import axios from "axios";
import React, { useState } from "react";
import { notify } from "../Toast/Toast";
import { useWebSocket } from "../../context/WebSocketProvider";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

export default function CasinoBotsModal({ open, setOpen, onBotCreated }) {
    const [isClosing, setIsClosing] = useState(false); // State to manage closing animation
    const { colors } = useWebSocket();
    const [botName, setBotName] = useState("");

    const handleOpenWithAnimation = () => {
        if (open) {
            // If modal is open, initiate close animation
            setIsClosing(true);
        } else {
            setOpen(true); // Directly open the modal
        }
    };

    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsClosing(false); // Reset closing state
            setOpen(false); // Actually close the modal after animation
        }
    };

    const handleName = (e) => {
        setBotName(e.target.value);
    };

    const handleAddBot = () => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/bots/create`,
                {
                    username: botName,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }
            )
            .then((response) => {
                const content = (
                    <div className="toast-content">
                        <div className="toast-text">
                            <div className="toast-details">
                                Bot {botName} created successfully!
                            </div>
                        </div>
                    </div>
                );
                notify(content, "success", colors);
                setBotName("");
                if (onBotCreated) {
                    onBotCreated(); // Trigger callback to fetch bots
                }
            })
            .catch((error) => {
                console.error("Error creating bot:", error);
                const errorMessage = error.response?.data?.message || "Failed to create bot.";
                notify(<div>{errorMessage}</div>, "error", colors);
            });
    };
    const generateRandomUsername = () => {
        // Arrays of adjectives and nouns for username generation
        const adjectives = [
            "Swift",
            "Silent",
            "Brave",
            "Mighty",
            "Clever",
            "Noble",
            "Sly",
            "Wise",
            "Fierce",
            "Bold",
            "Loyal",
            "Jolly",
            "Happy",
            "Bright",
            "Eager",
            "Shiny",
            "Lucky",
            "Curious",
            "Witty",
            "Charming",
            "Fearless",
            "Gentle",
            "Radiant",
            "Daring",
            "Gallant",
            "Mystic",
            "Stealthy",
            "Epic",
            "Heroic",
            "Stormy",
            "Majestic",
            "Blazing",
            "Vigilant",
            "Valiant",
            "Nimble",
            "Vivid",
            "Mirthful",
            "Vast",
            "Dazzling",
            "Electric",
            "Silent",
            "Crimson",
            "Frosty",
            "Thunderous",
            "Golden",
            "Vibrant",
            "Shadowy",
            "Whispering",
            "Eternal",
            "Divine",
            "Cosmic",
            "Phantom",
            "Celestial",
            "Raging",
            "Zealous",
            "Arcane",
            "Swift",
            "Glorious",
            "Grim",
            "Daring",
            "Harmonic",
            "Infernal",
            "Primal",
            "Blissful",
            "Glimmering",
            "Radiant",
            "Serene",
            "Spectral",
            "Vigilant",
            "Humble",
            "Benevolent",
            "Chivalrous",
            "Proud",
            "Tenacious",
            "Zephyr",
            "Gleaming",
            "Nebulous",
            "Infinite",
            "Cunning",
            "Vivacious",
        ];

        const nouns = [
            "Lion",
            "Tiger",
            "Panther",
            "Fox",
            "Wolf",
            "Eagle",
            "Hawk",
            "Dragon",
            "Bear",
            "Falcon",
            "Knight",
            "Wizard",
            "Ranger",
            "Ninja",
            "Samurai",
            "Pirate",
            "Viking",
            "Ghost",
            "Warrior",
            "Sage",
            "Phoenix",
            "Griffin",
            "Shadow",
            "Starlord",
            "Rogue",
            "Hunter",
            "Sorcerer",
            "Guardian",
            "Raven",
            "Owl",
            "Cheetah",
            "Leopard",
            "Lynx",
            "Cobra",
            "Serpent",
            "Golem",
            "Druid",
            "Paladin",
            "Samurai",
            "Ronin",
            "Shaman",
            "Berserker",
            "Assassin",
            "Mystic",
            "Oracle",
            "Gladiator",
            "Samurai",
            "Monk",
            "Samurai",
            "Jester",
            "Titan",
            "Reaper",
            "Wanderer",
            "Nomad",
            "Crusader",
            "Behemoth",
            "Champion",
            "Sphinx",
            "Chimera",
            "Wraith",
            "Gargoyle",
            "Specter",
            "Minotaur",
            "Harpy",
            "Unicorn",
            "Pegasus",
            "Hydra",
            "Cyclops",
            "Basilisk",
            "Gryphon",
            "Necromancer",
            "Invoker",
            "Warlord",
            "Protector",
            "Sphinx",
            "Pharaoh",
            "Demon",
            "Angel",
            "Djinn",
            "Demon",
            "Valkyrie",
            "Sentinel",
            "Ravager",
            "Abyss",
            "Stalker",
            "Nightmare",
            "Prophet",
            "Crusader",
            "Wanderer",
            "Outlaw",
        ];

        // Randomly select an adjective and a noun from the arrays
        const randomAdjective =
            adjectives[Math.floor(Math.random() * adjectives.length)];
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

        // Generate a random number for uniqueness
        const randomNumber = Math.floor(Math.random() * 1000);

        // Combine the adjective, noun, and number to create a username
        const username = `${randomAdjective}${randomNoun}${randomNumber}`;

        return username;
    };
    const randomizeBotName = () => {
        // axios.get('https://randomuser.me//api').then(response => {
        //     setBotName(response.data.results[0].login.username);
        //     console.log(response.data.results[0].login.username);
        // }
        // );
        const botname = generateRandomUsername();
        setBotName(botname);
    };

    const newShade = (hexColor, magnitude) => {
        if (!hexColor || typeof hexColor !== "string") {
            console.error("Invalid hexColor provided:", hexColor);
            return "#000000"; // Return a default color or handle the error as needed
        }

        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    return (
        <Dialog
            open={open}
            className="relative z-10"
            onClose={() => handleOpenWithAnimation()}
        >
            <DialogBackdrop
                className={`fixed inset-0 transition-opacity ${isClosing ? "betbackdropclose" : "betbackdropopen"
                    }`}
                style={{
                    backgroundColor: colors?.mainColorBG + "90",
                }}
            />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        className={`relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all w-[90%] sm:my-8 sm:w-full sm:max-w-lg p-8 ${isClosing ? "modalloginclose" : "modalloginopen"
                            }`}
                        onAnimationEnd={() => handleAnimationEnd()}
                        style={{
                            backgroundColor: newShade(colors?.secondaryColorBG, 10),
                            border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
                        }}
                    >
                        <div
                            className="p-4 rounded-md"
                            style={{
                                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                            }}
                        >
                            <div className="flex flex-row gap-x-2">
                                <input
                                    type="text"
                                    className="block w-full p-2 text-sm font-bold text-white rounded-md ps-4 focus-visible:outline-none"
                                    placeholder="username..."
                                    value={botName ? botName : ""}
                                    onChange={() => handleName()}
                                    style={{
                                        backgroundColor: colors?.mainColorBG,
                                    }}
                                ></input>
                                <button
                                    className="p-2 text-sm font-medium text-black uppercase transition-all duration-200 rounded hover:brightness-110"
                                    style={{
                                        backgroundColor: colors?.profitColor,
                                    }}
                                    onClick={() => randomizeBotName()}
                                >
                                    Randomize
                                </button>
                            </div>
                            <div className="flex flex-col pt-2 gap-y-2">
                                <button
                                    className="p-2 rounded text-black text-sm uppercase font-medium transition-all duration-200 bg-[#72F238] hover:brightness-110"
                                    onClick={() => handleAddBot()}
                                    disabled={!botName}
                                    style={{
                                        backgroundColor: colors?.profitColor,
                                    }}
                                >
                                    Add Bot
                                </button>
                            </div>
                            <div className="flex flex-col pt-2 gap-y-2">
                                <button
                                    className="p-2 rounded text-black text-sm uppercase font-medium transition-all duration-200 bg-[#72F238] hover:brightness-110"
                                    onClick={() => handleOpenWithAnimation()}
                                    style={{
                                        backgroundColor: colors?.lossColor,
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
