import { createContext, useState, useContext } from "react";

// Create the context
const BetContext = createContext();

// Create a provider component
export const BetProvider = ({ children }) => {
    const [selectedBet, setSelectedBet] = useState(null);

    return (
        <BetContext.Provider value={{ selectedBet, setSelectedBet }}>
            {children}
        </BetContext.Provider>
    );
};

// Hook to use the bet context in any component
export const useBetContext = () => {
    return useContext(BetContext);
};