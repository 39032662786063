import React, { createContext, useContext, useState } from 'react';

const TimerangepickContext = createContext(null);

export const TimerangepickProvider = ({ children }) => {
    const [timerangepick, setTimerangepick] = useState('tick');

    return (
        <TimerangepickContext.Provider value={{ timerangepick, setTimerangepick }}>
            {children}
        </TimerangepickContext.Provider>
    );
};

export const useTimerangepick = () => useContext(TimerangepickContext);