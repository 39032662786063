import { AnimatePresence, motion } from 'framer-motion';
import { BiSolidUpArrow } from 'react-icons/bi';
import { AiOutlineDollar } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

const Notifications = ({
    notificationsOpen,
    NotificationsUser,
    handleClearNotifications,
    colors
}) => {
    // Define variants for open and closed states
    const variants = {
        open: {
            opacity: 1,
            height: 'auto',
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        closed: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        }
    };

    return (
        <AnimatePresence>
            {notificationsOpen && (
                <div
                    className="absolute md:right-1 flex justify-end z-10 top-7 overflow-hidden shadow-lg rounded"
                    style={{ backgroundColor: colors?.secondaryColorBG }}
                >
                    {/* Header */}
                    <div className="px-3 py-2 text-xs">
                        <div className="flex items-center justify-between">
                            <p className="uppercase text-[#B1B6C6]">Notifications</p>
                            <button
                                className="px-2 py-1 text-black text-xs uppercase font-medium transition-all duration-200 hover:brightness-75 rounded-[5px] disabled:opacity-50 disabled:cursor-not-allowed"
                                style={{ backgroundColor: colors?.secondaryButton?.mainColor }}
                                onClick={handleClearNotifications}
                            >
                                <span>Clear all</span>
                            </button>
                        </div>

                        {/* Notifications List */}
                        <motion.div
                            layout
                            key="notifications"
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants}
                            className="overflow-y-auto overflow-x-hidden min-w-[252px] max-h-80 mt-3">
                            <AnimatePresence>
                                {NotificationsUser.length !== 0 ? (
                                    NotificationsUser.map((notification, index) => {
                                        return (
                                            <motion.div
                                                key={notification.id}
                                                layout
                                                initial={{ opacity: 0, y: -70 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                                className="flex items-center w-full p-2 mb-2 rounded"
                                                style={{ backgroundColor: colors?.mainColorBG }}
                                            >
                                                <div className="flex flex-col items-start">
                                                    <div className="pb-2">
                                                        <span>{notification.type === "newBet" && "New Bet"}{notification.type === "cashedOutBet" && "Cashed Out Bet"}</span>
                                                    </div>
                                                    <div className="flex flex-row gap-x-2 items-center">
                                                        <span>Coin:</span>
                                                        <img
                                                            className="w-4 h-4 rounded-full"
                                                            src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${notification.cryptoId}.png`}
                                                            alt={`${notification.coin}`}
                                                        />
                                                        <span>Wager:</span>
                                                        <span className="text-[#B1B6C6] flex items-center gap-x-0.5">
                                                            $ {notification.amount}
                                                        </span>
                                                        <span>Multiplier:</span>
                                                        <span className="text-[#B1B6C6] flex items-center">
                                                            {notification.multiplier}
                                                            <IoMdClose />
                                                        </span>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        )
                                    })
                                ) : (
                                    <motion.div
                                        key="no-notifications"
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 1, ease: 'easeInOut' }}
                                        className="flex items-center w-full p-2 mb-2 rounded justify-center"
                                        style={{ backgroundColor: colors?.mainColorBG }}
                                    >
                                        <p className="text-[#B1B6C6]">No notifications!</p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    </div>
                </div >
            )}
        </AnimatePresence >
    );
};

export default Notifications;
