import { useEffect, useRef, useState } from "react";
import DashboardCasino from "./DashboardMenu";
import axios from "axios";
import { useWebSocket } from "../../context/WebSocketProvider";
import { RiArrowRightUpLine, RiArrowRightDownLine } from "react-icons/ri";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Pie, PieChart, Cell } from 'recharts';
import { FaCircle } from "react-icons/fa";
import { PiPokerChipLight } from "react-icons/pi";


const CasinoStatistics = () => {
  const { colors } = useWebSocket();
  const [users, setUsers] = useState([]);
  const [bets, setBets] = useState([]);
  const chartRef = useRef(null); // Ref for the chart container

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/user/getAll`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const getBets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/bet/getAll`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setBets(response.data);
      } catch (error) {
        console.error("Error fetching bets:", error);
      }
    };

    getUsers();
    getBets();
  }, []);

  // 2. Filter out bets made by bots
  const filteredBets = bets.filter((bet) => {
    const user = users.find((user) => user.id === bet.userId); // Adjust 'userId' based on your data structure
    return !!user; // Return true if user is found, otherwise false
  });

  const totalUsers = users?.length;
  const totalBets = filteredBets?.length;

  const calculateTotalRevenue = () => {
    return filteredBets
      .filter((bet) => bet.result !== "pending")
      .reduce((total, bet) => total - bet.profitOrLoss, 0)
      .toFixed(2);
  };

  const calculateRevenueOverTime = (period) => {
    const now = new Date();

    // Helper function to get the start of the week (Monday)
    const getStartOfWeek = (date) => {
      const dayOfWeek = date.getDay(); // 0 (Sun) to 6 (Sat)
      const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Adjust so Monday is 0
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - diffToMonday);
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    };

    // Helper function to get the end of the week (Sunday)
    const getEndOfWeek = (startOfWeek) => {
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);
      return endOfWeek;
    };

    // Helper function to get the start and end of last month
    const getLastMonthRange = () => {
      const year = now.getFullYear();
      const month = now.getMonth(); // 0-indexed (0 = January)

      // Determine last month and handle year transition
      const lastMonth = month === 0 ? 11 : month - 1;
      const lastMonthYear = month === 0 ? year - 1 : year;

      const startOfLastMonth = new Date(lastMonthYear, lastMonth, 1, 0, 0, 0, 0);
      const endOfLastMonth = new Date(lastMonthYear, lastMonth + 1, 0, 23, 59, 59, 999);

      return { startOfLastMonth, endOfLastMonth };
    };

    const filteredByTime = filteredBets.filter((bet) => {
      const betDate = new Date(bet.updatedAt);

      if (bet.result === "pending") {
        return false;
      }

      switch (period) {
        case "daily":
          return betDate.toDateString() === now.toDateString();

        case "weekly":
          const startOfCurrentWeek = getStartOfWeek(now);
          const endOfCurrentWeek = getEndOfWeek(startOfCurrentWeek);
          return betDate >= startOfCurrentWeek && betDate <= endOfCurrentWeek;

        case "lastWeek":
          const startOfCurrent = getStartOfWeek(now);
          const startOfLastWeek = new Date(startOfCurrent);
          startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);
          const endOfLastWeek = new Date(startOfLastWeek);
          endOfLastWeek.setDate(endOfLastWeek.getDate() + 6);
          endOfLastWeek.setHours(23, 59, 59, 999);
          return betDate >= startOfLastWeek && betDate <= endOfLastWeek;

        case "monthly":
          return (
            betDate.getMonth() === now.getMonth() &&
            betDate.getFullYear() === now.getFullYear()
          );

        case "lastMonth":
          const { startOfLastMonth, endOfLastMonth } = getLastMonthRange();
          return betDate >= startOfLastMonth && betDate <= endOfLastMonth;

        default:
          return false;
      }
    });

    return filteredByTime
      .reduce((total, bet) => total - bet.profitOrLoss, 0)
      .toFixed(2);
  };

  const calculateBetsThisWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the start of the week (assuming week starts on Sunday)
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0);

    // Calculate the end of the week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    // Filter bets that were placed this week
    const betsThisWeek = filteredBets.filter((bet) => {
      const betDate = new Date(bet.updatedAt);
      return betDate >= startOfWeek && betDate <= endOfWeek;
    });

    return betsThisWeek.length;
  };

  const calculateBetsLastWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Start of the current week (Sunday)
    const startOfCurrentWeek = new Date(now);
    startOfCurrentWeek.setDate(now.getDate() - dayOfWeek);
    startOfCurrentWeek.setHours(0, 0, 0, 0);

    // Start of last week
    const startOfLastWeek = new Date(startOfCurrentWeek);
    startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);
    startOfLastWeek.setHours(0, 0, 0, 0);

    // End of last week
    const endOfLastWeek = new Date(startOfCurrentWeek);
    endOfLastWeek.setSeconds(endOfLastWeek.getSeconds() - 1); // Saturday 23:59:59

    // Filter bets that were placed last week
    const betsLastWeek = filteredBets.filter((bet) => {
      const betDate = new Date(bet.updatedAt);
      return betDate >= startOfLastWeek && betDate <= endOfLastWeek;
    });

    return betsLastWeek.length;
  };

  const calculateBetsDifferencePercentage = () => {
    const thisWeek = calculateBetsThisWeek();
    const lastWeek = calculateBetsLastWeek();

    const difference = thisWeek - lastWeek;
    const percentage = (difference / lastWeek) * 100;

    return (
      <div className={`flex items-center text-xs`}
        style={{
          color: difference >= 0 ? colors?.profitColor : colors?.lossColor,
        }}>
        {difference >= 0 ? <RiArrowRightUpLine className="w-4 h-4" /> : <RiArrowRightDownLine className="w-4 h-4" />} {!isFinite(percentage) ? "100" : percentage.toFixed(2)}%
      </div>
    );
  };

  const rankCoins = () => {
    const coinCount = {};

    // Count the occurrences of each coin and store cryptoId
    filteredBets.forEach((bet) => {
      const coin = bet.coin.replace(/usdt$/i, "");

      if (!coinCount[coin]) {
        // Initialize with count and cryptoId from the first occurrence
        coinCount[coin] = { count: 1, cryptoId: bet.cryptoId };
      } else {
        // Increment the count; assume cryptoId is the same for the same coin
        coinCount[coin].count += 1;
      }
    });

    // Convert the coinCount object to an array of {coin, count, cryptoId} objects
    const sortedCoins = Object.entries(coinCount)
      // Sort the array in descending order based on count
      .sort((a, b) => b[1].count - a[1].count)
      // Map to a more readable format
      .map(([coin, data]) => ({
        coin,
        count: data.count,
        cryptoId: data.cryptoId
      }));

    return sortedCoins;
  };

  const mostFrequentBetSize = () => {
    const betSizeCount = {};
    filteredBets.forEach((bet) => {
      betSizeCount[bet.amount] = (betSizeCount[bet.amount] || 0) + 1;
    });
    return Object.keys(betSizeCount).reduce(
      (a, b) => (betSizeCount[a] > betSizeCount[b] ? a : b),
      0
    );
  };

  const AverageBetSize = () => {
    const totalAmount = filteredBets.reduce(
      (total, bet) => total + bet.amount,
      0
    );
    return (totalAmount / (totalBets || 1)).toFixed(2); // Avoid division by zero
  };

  const activeBetsCount = () => {
    return filteredBets.filter((bet) => bet.result === "pending").length;
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const getFormattedValue = (value) => {
    return Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };



  const calculateMonthlyRevenueDifference = () => {
    const thisMonthRevenue = parseFloat(calculateRevenueOverTime("monthly"));
    const lastMonthRevenue = parseFloat(calculateRevenueOverTime("lastMonth"));

    const difference = thisMonthRevenue - lastMonthRevenue;
    const percentage = (difference / Math.abs(lastMonthRevenue)) * 100;

    return (
      <div className="flex items-center text-xs gap-x-2">
        <div className="flex items-center" style={{
          color: difference >= 0 ? colors?.profitColor : colors?.lossColor,
        }
        }>
          {difference >= 0 ? (
            <RiArrowRightUpLine className="w-4 h-4" />
          ) : (
            <RiArrowRightDownLine className="w-4 h-4" />
          )
          }
          {!isFinite(percentage) ? "100" : percentage.toFixed(2)}%
        </div>
        <div style={{
          color: newShade(colors?.secondaryColorBG, 170),
        }}>{thisMonthRevenue > 0 ? "+" : ""}${thisMonthRevenue} this month</div>
      </div>
    );
  };

  const calculateWeeklyRevenueDifference = () => {
    const thisWeekRevenue = parseFloat(calculateRevenueOverTime("weekly"));
    const lastWeekRevenue = parseFloat(calculateRevenueOverTime("lastWeek"));

    const difference = thisWeekRevenue - lastWeekRevenue;
    const percentage = (difference / Math.abs(lastWeekRevenue)) * 100;

    return (
      <div className="flex items-center text-xs gap-x-2">
        <div className="flex items-center" style={{
          color: difference >= 0 ? colors?.profitColor : colors?.lossColor,
        }
        }>
          {difference >= 0 ? (
            <RiArrowRightUpLine className="w-4 h-4" />
          ) : (
            <RiArrowRightDownLine className="w-4 h-4" />
          )
          }
          {!isFinite(percentage) ? "100" : percentage.toFixed(2)}%
        </div>
        <div style={{
          color: newShade(colors?.secondaryColorBG, 170),
        }}>{thisWeekRevenue > 0 ? "+" : ""}${thisWeekRevenue} this week</div>
      </div>
    );
  };

  const getDailyRevenueData = () => {
    const revenueMap = {};

    // Get today's date and the date 6 days ago
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 6); // Including today, total 7 days

    // Initialize revenueMap with the last 7 days set to 0
    for (let i = 0; i < 7; i++) {
      const date = new Date(sevenDaysAgo);
      date.setDate(sevenDaysAgo.getDate() + i);
      const dateKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      revenueMap[dateKey] = 0;
    }

    filteredBets.forEach((bet) => {
      if (bet.result === "pending") return;

      const date = new Date(bet.updatedAt);
      // Reset time to midnight for accurate comparison
      date.setHours(0, 0, 0, 0);

      // Check if the bet's date is within the last 7 days
      if (date < sevenDaysAgo || date > today) return;

      const dateKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

      // Initialize the date in revenueMap if not already present
      if (!revenueMap[dateKey]) {
        revenueMap[dateKey] = 0;
      }

      // Assuming profitOrLoss is positive for profit and negative for loss
      revenueMap[dateKey] -= bet.profitOrLoss;
    });

    // Convert to array and sort by date in ascending order
    const revenueData = Object.keys(revenueMap)
      .sort((a, b) => new Date(a) - new Date(b))
      .map((dateKey) => {
        const date = new Date(dateKey);
        const formattedDate = `${String(date.getDate()).padStart(2, "0")}.${String(date.getMonth() + 1).padStart(2, "0")}`;

        return {
          name: formattedDate, // Format: "dd.MM"
          uv: revenueMap[dateKey],
        };
      });

    return revenueData;
  };

  const ActiveUsers = () => {
    // check if user has bets in the last 7 days
    const activeUsers = users.filter((user) => {
      const userBets = filteredBets.filter((bet) => bet.userId === user.id);
      const last7Days = new Date();
      last7Days.setDate(last7Days.getDate() - 7);

      return userBets.some((bet) => new Date(bet.updatedAt) > last7Days);
    }
    );

    return activeUsers.length;
  };

  const compareNewUsersWithLastWeek = () => {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);
    const newUsersThisWeek = users.filter((user) => new Date(user.createdAt) > last7Days).length;
    const newUsersLastWeek = users.filter((user) => new Date(user.createdAt) > new Date(last7Days).setDate(last7Days.getDate() - 7)).length;

    const difference = newUsersThisWeek - newUsersLastWeek;
    const percentage = (difference / newUsersLastWeek) * 100;

    return (
      <div className="flex items-center text-xs gap-x-2">
        <div className="flex items-center" style={{
          color: difference >= 0 ? colors?.profitColor : colors?.lossColor,
        }
        }>
          {difference >= 0 ? (
            <RiArrowRightUpLine className="w-4 h-4" />
          ) : (
            <RiArrowRightDownLine className="w-4 h-4" />
          )
          }
          {!isFinite(percentage) ? "100" : percentage.toFixed(2)}%
        </div>
        <div style={{
          color: newShade(colors?.secondaryColorBG, 170),
        }}>{newUsersThisWeek > 0 ? "+" : ""}{newUsersThisWeek} new users this week</div>
      </div>
    );
  };

  const InactiveUsers = () => {
    return totalUsers - ActiveUsers();
  };


  const formatYAxis = (value) => {
    if (value >= 1000 || value <= -1000) {
      // Divide by 1000 and fix to one decimal place if necessary
      const formatted = value / 1000;
      return `${formatted % 1 === 0 ? formatted : formatted.toFixed(1)}k`;
    }
    return value;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 rounded-md" style={{
          backgroundColor: newShade(colors?.secondaryColorBG, 30),
          border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
        }}>
          <p>{`Day: ${label}`}</p>
          <p style={{
            color: payload[0].value > 0 ? colors?.profitColor : colors?.lossColor,
          }}>{`$ ${formatYAxis(payload[0].value.toFixed(2))}`}</p>
        </div>
      );
    }

    return null;
  };

  const ReChartsAreaChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={730} height={250} data={getDailyRevenueData()}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors?.profitColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={newShade(colors?.profitColor, 20)} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke={newShade(colors?.secondaryColorBG, 150)} />
          <YAxis allowDecimals={true} stroke={newShade(colors?.secondaryColorBG, 150)}
            tickFormatter={formatYAxis} />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Area type="monotone" dataKey="uv" stroke={colors?.profitColor} fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
  const winLossRatio = () => {
    let wins = 0;
    let losses = 0;
    filteredBets.forEach((bet) => {
      if (bet.result === "win") wins++;
      else if (bet.result !== "win") losses++;
    });
    return [
      { name: "Wins", value: wins, fill: colors?.profitColor },
      { name: "Losses", value: losses, fill: colors?.lossColor },
    ];
  };
  const dataWinLoss = winLossRatio();

  const winLossRatioPieChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight="204px">
        <PieChart>
          <Pie
            strokeWidth={10}
            stroke={newShade(colors?.mainColorBG, 20)}
            dataKey="value"
            data={dataWinLoss}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={90}
            label={false}
            labelLine={false}
          />
          {dataWinLoss.map((entry, index) => (
            <Cell
              key={`cell-${index}`} fill={entry.fill}
            />
          ))}
        </PieChart>
      </ResponsiveContainer>
    )
  };

  return (
    <div
      className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG
      }}
    >
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4">
        <h1 className="pb-5 text-4xl text-left font-bold text-white"> Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 overflow-y-auto w-full">
          <div
            className="p-4 text-center rounded-lg shadow-md col-span-2 lg:col-span-1"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}
          >
            <p className="text-base text-left pb-4 text-white">General Statistics</p>
            <div className={`text-left text-white p-4 rounded-lg shadow-md`}
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
              }}>
              <div>
                <p style={{
                  color: newShade(colors?.secondaryColorBG, 170),
                }}>Total Revenue</p>
                <p className="text-3xl font-bold text-white">
                  $ {getFormattedValue(calculateTotalRevenue())}
                </p>
                {calculateMonthlyRevenueDifference()}
              </div>
              <div className="my-4"
                style={{
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 50)}`,
                }}></div>
              <div>
                <p style={{
                  color: newShade(colors?.secondaryColorBG, 170),
                }}>Income</p>
                <p className="text-3xl font-bold text-white">
                  $ {getFormattedValue(calculateRevenueOverTime("monthly"))}
                </p>
                {calculateWeeklyRevenueDifference()}
                <div className="my-4"
                  style={{
                    border: `1px solid ${newShade(colors?.secondaryColorBG, 50)}`,
                  }}></div>
                <div className="flex justify-between">
                  <p className="text-sm" style={{
                    color: newShade(colors?.secondaryColorBG, 170),
                  }}>Bets placed this week: <span className="font-semibold text-white">{calculateBetsThisWeek()}</span></p>
                  {calculateBetsDifferencePercentage()}
                </div>
                <div className="flex justify-between">
                  <p className="text-sm" style={{
                    color: newShade(colors?.secondaryColorBG, 170),
                  }}>Total Bets Placed: <span className="font-semibold text-white">{totalBets}</span></p>
                  <p className="text-sm" style={{
                    color: newShade(colors?.secondaryColorBG, 170),
                  }}>Active Bets: <span className="font-semibold text-white">{activeBetsCount()}</span></p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm" style={{
                    color: newShade(colors?.secondaryColorBG, 170),
                  }}>Frequent Bet: <span className="font-semibold text-white">$ {mostFrequentBetSize()}</span></p>
                  <p className="text-sm" style={{
                    color: newShade(colors?.secondaryColorBG, 170),
                  }}>Average Bet: <span className="font-semibold text-white">$ {AverageBetSize()}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md col-span-2"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}
          >
            <p className="text-base text-left pb-4 text-white">
              Daily Income
            </p>
            <div
              ref={chartRef}
              className={`text-left text-white p-4 rounded-lg shadow-md`}
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                width: "100%",
                height: "314px",
              }}
            >
              {ReChartsAreaChart()}
            </div>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md col-span-2 lg:col-span-1"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}>
            <p className="text-base text-left pb-4 text-white">
              Win/Loss Ratio
            </p>
            <div className={`text-left text-white p-4 rounded-lg shadow-md`}
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                minWidth: "100%",
                minHeight: "298px",
              }}>
              {winLossRatioPieChart()}
              {dataWinLoss.map((entry, index) => (
                <div key={`legend-${index}`} className="grid grid-cols-4 text-center items-center gap-x-2 text-sm">
                  <FaCircle className="w-4 h-4" style={{
                    color: entry.fill,
                  }} />
                  <div>{entry.name}</div>
                  <div>{entry.value}</div>
                  <div>{((entry.value / totalBets) * 100).toFixed(2)}%</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="p-4 text-center rounded-lg shadow-md col-span-2 lg:col-span-1"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}
          >
            <p className="text-base text-left pb-4 text-white">Activity</p>
            <div className={`text-left text-white p-4 rounded-lg shadow-md`}
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
              }}>
              <p>Total Users</p>
              <p className="text-3xl font-bold text-white">{totalUsers}</p>
              {compareNewUsersWithLastWeek()}
              <div className="my-4"
                style={{
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 50)}`,
                }}></div>
              <p>Users Active</p>
              <p className="text-3xl font-bold text-white">{ActiveUsers()}</p>
              <div className="my-4"
                style={{
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 50)}`,
                }}></div>
              <p>Users Inactive</p>
              <p className="text-3xl font-bold text-white">{InactiveUsers()}</p>
            </div>
          </div>
          <div className="p-4 text-center rounded-lg shadow-md col-span-2 lg:col-span-1"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}
          >
            <p className="text-base text-left pb-4 text-white">
              Most Frequent Coins
            </p>
            <div className={`text-left text-white p-4 rounded-lg shadow-md overflow-y-auto max-h-[298px]`}
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 30),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
              }}>
              {rankCoins().map((coin, index) => (
                <div key={index} className="flex items-center justify-between text-white p-2 mb-2 rounded-md" style={{
                  backgroundColor: newShade(colors?.secondaryColorBG, 20),
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                }}>
                  <div className="flex items-center gap-x-2">
                    <div>{index + 1}.</div>
                    <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin.cryptoId}.png`} alt={`${coin.coin}`} className="w-6 h-6 rounded-full" />
                    <div>{coin.coin}</div>
                  </div>
                  <div className="flex items-center gap-x-1"><PiPokerChipLight />{coin.count}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CasinoStatistics;
