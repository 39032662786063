import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProviderWrapper } from "./context/auth.context";
import { WebSocketProvider } from './context/WebSocketProvider';
import { TimerangepickProvider } from './context/timerangepick';
import { BalanceUserProvider } from "./context/balanceuser";
import { BetProvider } from "./context/bet.context";
import { NotificationsUserProvider } from "./context/notificationsuser.context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <AuthProviderWrapper>
      <NotificationsUserProvider>
        <BetProvider>
          <WebSocketProvider>
            <TimerangepickProvider>
              <BalanceUserProvider>
                <App />
              </BalanceUserProvider>
            </TimerangepickProvider>
          </WebSocketProvider>
        </BetProvider>
      </NotificationsUserProvider>
    </AuthProviderWrapper>
  </Router>
);
