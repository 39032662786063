import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { notify } from "../Toast/Toast";
import DashboardCasino from "./DashboardMenu";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../context/WebSocketProvider";
import { FaAngleDown, FaCheck, FaTrash } from "react-icons/fa";
import CasinoBotsModal from "./CasinoBotsModal";

const CasinoBots = () => {
  const [bots, setBots] = useState([]);
  const { colors } = useWebSocket();
  const navigate = useNavigate();
  const [coins, setCoins] = useState([]);
  const [dropdowns, setDropdowns] = useState({});
  const [dropdownPositions, setDropdownPositions] = useState({});
  const containerRef = useRef(null);
  const dropdownRefs = useRef({});
  const [cashoutIntervals, setCashoutIntervals] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  // Function to fetch all bots
  const fetchBots = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bots/getall`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setBots(response.data);
        // Initialize cashoutIntervals
        const initialIntervals = response.data.reduce((acc, bot) => {
          acc[bot.id] = bot.cashoutInterval;
          return acc;
        }, {});
        setCashoutIntervals(initialIntervals);
      })
      .catch((error) => {
        console.error("Error fetching bots:", error);
        notify("Failed to fetch bots", "error", colors);
      });
  };

  useEffect(() => {
    fetchBots();

    // Fetch all crypto coins
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/crypto/cryptocoins`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setCoins(response.data);
      })
      .catch((error) => {
        console.error("Error fetching crypto coins:", error);
        notify("Failed to fetch crypto coins", "error", colors);
      });
  }, [colors]);

  const handleActiveChange = (bot) => {
    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/bots/update/${bot.id}`,
        {
          active: !bot.active,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setBots(bots.map((b) => (b.id === bot.id ? response.data : b)));
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot {response.data.active ? "activated" : "deactivated"}{" "}
                successfully!
              </div>
            </div>
          </div>,
          "success",
          colors
        );
      });
  };

  const handleHiddenChange = (bot) => {
    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/bots/updateHidden/${bot.id}`,
        {
          hidden: !bot.hidden,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setBots(bots.map((b) => (b.id === bot.id ? response.data : b)));
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot username {response.data.hidden ? "hidden" : "visible"}
              </div>
            </div>
          </div>,
          "success",
          colors
        );
      })
      .catch((error) => {
        console.error("Error updating bot hidden status:", error);
        notify("Failed to update bot hidden status", "error", colors);
      });
  };

  const handleDeleteBot = (bot) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the bot "${bot.username}"? This action cannot be undone.`
    );
    if (!confirmDelete) return;

    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/bots/delete/${bot.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(() => {
        setBots(bots.filter((b) => b.id !== bot.id));
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot "{bot.username}" deleted successfully!
              </div>
            </div>
          </div>,
          "success",
          colors
        );
      })
      .catch((error) => {
        console.error("Error deleting bot:", error);
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Failed to delete bot "{bot.username}"
              </div>
            </div>
          </div>,
          "error",
          colors
        );
      });
  };

  const handleActiveAllChange = () => {
    const allInactive = bots.every((bot) => bot.active === false);

    if (allInactive) {
      const content = (
        <div className="toast-content">
          <div className="toast-text">
            <div className="toast-details">All bots are already inactive!</div>
          </div>
        </div>
      );
      notify(content, "error", colors);
      return;
    }

    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/bots/updateall`,
        {
          active: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then(() => {
        setBots(bots.map((bot) => ({ ...bot, active: false })));
        const content = (
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">Bots turned off successfully!</div>
            </div>
          </div>
        );
        notify(content, "success", colors);
      });
  };

  const toggleDropdown = (botId, index) => {
    setDropdowns((prevState) => {
      const isCurrentlyOpen = !!prevState[botId];
      const newState = isCurrentlyOpen ? {} : { [botId]: true };
      let isLastFive = false;
      if (!isCurrentlyOpen) {
        // Dropdown is being opened
        if (bots.length > 5) {
          isLastFive = index >= bots.length - 5;
        } else {
          isLastFive = index >= bots.length - 2;
        }
        setDropdownPositions((prev) => ({
          ...prev,
          [botId]: isLastFive ? "above" : "below",
        }));
      }

      return newState;
    });
  };

  const handleCashoutIntervalInputChange = (botId, value) => {
    setCashoutIntervals((prev) => ({
      ...prev,
      [botId]: value,
    }));
  };

  const handleCashoutIntervalSave = (bot) => {
    const newInterval = cashoutIntervals[bot.id];
    const parsedInterval = parseInt(newInterval, 10);

    // Validate the input
    if (isNaN(parsedInterval) || parsedInterval <= 0) {
      notify(
        <div className="toast-content">
          <div className="toast-text">
            <div className="toast-details">
              Invalid cashout interval for bot "{bot.username}". Please enter a
              positive number.
            </div>
          </div>
        </div>,
        "error",
        colors
      );
      return;
    }

    // Send PATCH request to update cashout interval
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/bots/updateCashout/${bot.id}`,
        { cashoutInterval: parsedInterval },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setBots(bots.map((b) => (b.id === bot.id ? response.data : b)));
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot "{bot.username}" cashout interval updated successfully!
              </div>
            </div>
          </div>,
          "success",
          colors
        );
      })
      .catch((error) => {
        console.error("Error updating cashout interval:", error);
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Failed to update cashout interval for bot "{bot.username}".
              </div>
            </div>
          </div>,
          "error",
          colors
        );
      });
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const handleCryptoChange = (bot, crypto, index) => {
    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/bots/updateCrypto/${bot.id}`,
        {
          crypto,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setBots(bots.map((b) => (b.id === bot.id ? response.data : b)));
        toggleDropdown(bot.id, index);
        notify(
          <div className="toast-content">
            <div className="toast-text">
              <div className="toast-details">
                Bot's crypto updated successfully!
              </div>
            </div>
          </div>,
          "success",
          colors
        );
      });
  };

  return (
    <div
      className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}
    >
      {open && (
        <CasinoBotsModal
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          onBotCreated={fetchBots} // Pass fetchBots as a prop
        />
      )}
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4 " ref={containerRef}>
        <div className="flex gap-x-2 justify-center">
          <button
            className="p-2 mt-5 text-sm font-medium text-black uppercase transition-all duration-200 rounded hover:brightness-110"
            onClick={handleActiveAllChange}
            style={{
              backgroundColor: colors?.profitColor,
            }}
          >
            Turn off bots
          </button>

          <button
            className="p-2 mt-5 text-sm font-medium text-black uppercase transition-all duration-200 rounded hover:brightness-110"
            onClick={handleOpen}
            style={{
              backgroundColor: colors?.profitColor,
            }}
          >
            Create Bots
          </button>
        </div>
        <div className="flex justify-center overflow-y-auto">
          <div
            className="p-2 mt-5 rounded-md relative overflow-y-auto"
            style={{
              backgroundColor: newShade(colors?.secondaryColorBG, 10),
              border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
            }}
          >
            <div className="flex flex-col p-2 pt-2 rounded-md gap-y-2">
              <div className="flex flex-col gap-y-2 justify-end">
                {bots.map((bot, index) => (
                  <div
                    key={bot.id}
                    className="flex justify-between rounded-md p-4 text-center gap-x-2 relative"
                    style={{
                      backgroundColor: newShade(colors?.secondaryColorBG, 30),
                      border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                    }}
                  >
                    <p className="text-xl font-semibold text-white self-center">
                      {bot.username.slice(0, 10)}
                    </p>
                    <div className="flex items-center space-x-4 text-white">
                      {/* Active Toggle */}
                      <label className="flex items-center cursor-pointer select-none">
                        <span className="mr-2">Active</span>
                        <div className="relative">
                          <input
                            type="checkbox"
                            className="sr-only"
                            checked={bot.active}
                            onChange={() => handleActiveChange(bot)}
                          />
                          <div
                            className={`block w-14 h-8 rounded-full`}
                            style={{
                              backgroundColor: bot.active
                                ? colors?.profitColor
                                : colors?.secondaryColorBG,
                            }}
                          ></div>
                          <div
                            className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-white transition-transform ${bot.active
                                ? "transform translate-x-full"
                                : "transform translate-x-0"
                              }`}
                          ></div>
                        </div>
                      </label>

                      {/* Hidden Toggle */}
                      <label className="flex items-center cursor-pointer select-none">
                        <span className="mr-2">Hidden</span>
                        <div className="relative">
                          <input
                            type="checkbox"
                            className="sr-only"
                            checked={bot.hidden}
                            onChange={() => handleHiddenChange(bot)}
                          />
                          <div
                            className={`block w-14 h-8 rounded-full`}
                            style={{
                              backgroundColor: bot.hidden
                                ? colors?.warningColor || "#f39c12" // Fallback color if warningColor is not defined
                                : colors?.secondaryColorBG,
                            }}
                          ></div>
                          <div
                            className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-white transition-transform ${bot.hidden
                                ? "transform translate-x-full"
                                : "transform translate-x-0"
                              }`}
                          ></div>
                        </div>
                      </label>

                      {/* Dropdown for Crypto Selection */}
                      <div className="relative">
                        <button
                          ref={(el) => (dropdownRefs.current[bot.id] = el)}
                          className="inline-flex justify-center items-center px-3 py-2 font-semibold shadow-sm rounded-md w-[130px]"
                          onClick={() => toggleDropdown(bot.id, index)}
                          style={{
                            backgroundColor: colors?.secondaryColorBG,
                          }}
                        >
                          {bot.crypto ? bot.crypto : "Select Crypto"}
                          <FaAngleDown className="ml-1" />
                        </button>
                        {dropdowns[bot.id] && coins && (
                          <div
                            className={`absolute left-0 z-50 w-full overflow-y-auto text-white rounded shadow-lg max-h-96 ${dropdownPositions[bot.id] === "above"
                                ? "bottom-full mb-2"
                                : "top-full mt-2"
                              }`}
                            style={{
                              backgroundColor: colors?.secondaryColorBG,
                            }}
                          >
                            <button
                              className="flex flex-row items-center justify-center w-full px-3 py-2 gap-x-2 hover:brightness-125 h-[40px]"
                              style={{
                                backgroundColor: colors?.secondaryColorBG,
                              }}
                              onClick={() =>
                                handleCryptoChange(bot, "Every Coin", index)
                              }
                            >
                              <span className="font-black text-xs">
                                EVERY COIN
                              </span>
                            </button>
                            {coins.map(
                              (crypto) =>
                                crypto.active && (
                                  <button
                                    key={crypto.id}
                                    className="flex flex-row items-center justify-between w-full px-3 py-2 gap-x-2 hover:brightness-125"
                                    style={{
                                      backgroundColor: colors?.secondaryColorBG,
                                    }}
                                    onClick={() =>
                                      handleCryptoChange(bot, crypto.symb, index)
                                    }
                                  >
                                    <img
                                      className="rounded-full w-6 h-6"
                                      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`}
                                      alt={`${crypto.name}`}
                                    />
                                    <span className="font-black text-xs">
                                      {crypto.symb}
                                    </span>
                                  </button>
                                )
                            )}
                          </div>
                        )}
                      </div>

                      {/* Cashout Interval Input */}
                      <div className="relative max-w-36">
                        <label className="block text-xs font-semibold absolute -top-2 left-2 z-10">
                          Cashout Interval (ms)
                        </label>
                        <input
                          value={cashoutIntervals[bot.id] || bot.cashoutInterval}
                          onChange={(e) =>
                            handleCashoutIntervalInputChange(
                              bot.id,
                              e.target.value
                            )
                          }
                          className="block w-full p-2 ps-4 text-sm rounded-md focus-visible:outline-none font-bold"
                          style={{
                            backgroundColor: colors?.secondaryColorBG,
                          }}
                        ></input>
                        <button
                          onClick={() => handleCashoutIntervalSave(bot)}
                          className="absolute top-0 bottom-0 right-1 py-0.5 my-1 px-2 rounded-md text-xs"
                          style={{
                            color: colors?.profitColor,
                            backgroundColor: colors?.mainColorBG,
                          }}
                        >
                          <FaCheck />
                        </button>
                      </div>

                      {/* Delete Button */}
                      <button
                        onClick={() => handleDeleteBot(bot)}
                        className="p-2 text-red-500 transition-colors duration-200 hover:text-red-700 rounded-md"
                        aria-label={`Delete bot ${bot.username}`}
                        title={`Delete bot ${bot.username}`}
                        style={{
                          backgroundColor: colors?.secondaryColorBG,
                        }}
                      >
                        <FaTrash size={20} />
                      </button>
                    </div>
                  </div>
                ))}
                {bots.length === 0 && (
                  <p className="text-center text-gray-400">No bots available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoBots;