import React, { useLayoutEffect, useRef, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from 'axios';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaAngleLeft, FaAngleRight, FaCaretDown, FaCog, FaAngleDoubleRight } from "react-icons/fa";
import { useWebSocket } from "../../context/WebSocketProvider.jsx";
import cryptoConfig from '../../cryptoConfig.js';
import { useTimerangepick } from '../../context/timerangepick.jsx';
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector.jsx';
import { useNavigate } from "react-router-dom";
import Loading from '../Loading/Loading.jsx';
import { useSwipeable } from 'react-swipeable';
import Statistics from '../Statistics/Statistics.jsx';
import GraphSettings from '../GraphSettings/GraphSettings.jsx';
import { AuthContext } from "../../context/auth.context";
import { useBetContext } from "../../context/bet.context.jsx";
import { throttle } from 'lodash';
import StatisticsMobile from '../Statistics/StatisticsMobile.jsx';

const CandlebarsChart = ({ coin, cryptoList }) => {
    const chartRef = useRef(null);
    const rootRef = useRef(null);
    const [chartData, setChartData] = useState([]);
    const [priceDirection, setPriceDirection] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSelector, setOpenSelector] = useState(null);
    const [openCryptoList, setOpenCryptoList] = useState(false);
    const { cryptoData, publicBets, currentOHLC, sendMessage, colors } = useWebSocket();
    const idsToSubtractOne = [1, 1027, 16116, 28752];
    const coinConfig = cryptoConfig[coin];
    const precision = coinConfig
        ? idsToSubtractOne.includes(coinConfig.id)
            ? coinConfig.precision - 1
            : coinConfig.precision
        : 2;
    let numberFormat = `#,###.${'0'.repeat(precision)}`;
    const coinId = coinConfig?.id || "1";
    const currentPrice = cryptoData.data[coin] ? cryptoData.data[coin] : null;
    const currentTime = cryptoData.lastFetched ? cryptoData.lastFetched : null;
    const dataBuffer = useRef([]);
    const lastCandleTime = useRef(null);
    const lastCandle = useRef(null);
    const { timerangepick } = useTimerangepick();
    const initialLoad = useRef(true);
    const navigate = useNavigate();
    const [showStatistics, setShowStatistics] = useState(false);
    const [closingStatistics, setClosingStatistics] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023); // State to track if it's a mobile device
    const [graphData, setGraphData] = useState([]);
    const { user } = useContext(AuthContext);
    const { selectedBet, setSelectedBet } = useBetContext();
    const previousMinMax = useRef({ min: null, max: null });
    const [chartDataAvailable, setChartDataAvailable] = useState(false)
    const [cryptoDataAvailable, setCryptoDataAvailable] = useState(false)

    const pendingPricesRef = useRef([]);

    useEffect(() => {
        if (cryptoData && !cryptoDataAvailable) {
            setCryptoDataAvailable(true);
        }
    }, [cryptoData]);

    const updateBustMaxPriceLine = (selectedBet, valueAxis, root) => {
        if (!valueAxis || !root) {
            console.warn("ValueAxis or Root is undefined.");
            return;
        }

        // Safely iterate over axisRanges using a for...of loop
        for (let range of valueAxis.axisRanges.values) {
            if (range && range.get("id") === "bustMaxPriceRange") {
                valueAxis.axisRanges.removeValue(range);
            }
        }

        // Remove any existing edge labels
        const existingEdgeLabel = valueAxis.chart.plotContainer.getPrivate("bustMaxPriceEdgeLabel");
        if (existingEdgeLabel) {
            valueAxis.chart.plotContainer.children.removeValue(existingEdgeLabel);
            valueAxis.chart.plotContainer.setPrivate("bustMaxPriceEdgeLabel", null);
        }

        if (selectedBet) {
            const calculateExitPrice = (entryPrice, multiplier, bet) => {
                const MIN_CHANGE = 0.00040; // Minimum change to prevent bust price being zero

                // Ensure the multiplier is greater than 1
                if (multiplier <= 1) {
                    multiplier = 1 + MIN_CHANGE;
                }

                // Desired profit is equal to the bet amount for 2x profit margin
                const desiredProfitMultiplier = 2; // 2x profit

                // Calculate the percentage change needed to achieve the desired profit
                // This assumes that the profit is directly proportional to the price change
                const desiredChange = (desiredProfitMultiplier - 1) / multiplier;

                let exitPrice;

                if (bet.direction === 'up') {
                    // For 'up' bets, the price needs to increase
                    exitPrice = entryPrice * (1 + desiredChange);
                } else if (bet.direction === 'down') {
                    // For 'down' bets, the price needs to decrease
                    exitPrice = entryPrice * (1 - desiredChange);
                } else {
                    throw new Error("Invalid bet direction. Use 'up' or 'down'.");
                }

                // Return the calculated exit price with the appropriate precision
                const precision = cryptoConfig[bet.coin]?.precision || 2;
                return parseFloat(exitPrice.toFixed(precision));
            };

            let exitPrice = calculateExitPrice(selectedBet.entryPrice, selectedBet.multiplier, selectedBet);
            const bustPriceColor = colors?.profitColor || am5.color(0xff4560); // Customize this as per your need

            const min = valueAxis.getPrivate("selectionMin");
            const max = valueAxis.getPrivate("selectionMax");

            if (exitPrice >= min && exitPrice <= max) {
                // Bust price is within the visible range
                let bustMaxPriceRange = valueAxis.createAxisRange(
                    valueAxis.makeDataItem({
                        value: exitPrice,
                        id: "bustMaxPriceRange" // Assign an id to easily identify this range later
                    })
                );

                let bustMaxPriceLabel = bustMaxPriceRange.get("label");
                if (bustMaxPriceLabel) {
                    bustMaxPriceLabel.setAll({
                        fill: am5.color(0x000000),
                        background: am5.PointedRectangle.new(root, {
                            fill: bustPriceColor,
                            pointerLength: 10,
                            pointerBaseWidth: 40,
                            pointerX: -6,
                            pointerY: 10,
                            cornerRadius: 3,
                            strokeOpacity: 0,
                        }),
                        padding: 5,
                        paddingLeft: 5,
                        fontSize: 13, // Adjust font size
                        text: 'Max Price',
                        layer: 100
                    });
                }

                // Customize the appearance of the bust price line
                let grid = bustMaxPriceRange.get("grid");
                if (grid) {
                    grid.setAll({
                        visible: true,
                        stroke: bustPriceColor,
                        strokeWidth: 2,
                        strokeOpacity: 1,
                        location: 1,
                        layer: 100
                    });
                }
            } else {
                // Bust price is out of view, place a label at the right edge
                let axisLength = valueAxis.get("renderer").axisLength();
                let labelY = exitPrice < min ? axisLength : 0;

                // Adjust labelY to ensure the label stays in bounds at the bottom
                if (labelY > axisLength - 22) {
                    labelY = axisLength - 22;  // Push it upwards to ensure it's not out of bounds
                }

                let edgeLabel = am5.Label.new(root, {
                    text: "Max Price",
                    fill: am5.color(0x000000), // Text color
                    background: am5.PointedRectangle.new(root, { // Using a rectangle instead of a PointedRectangle
                        fill: bustPriceColor,
                        pointerLength: 15,
                        pointerBaseWidth: 50,
                        pointerX: -6,
                        pointerY: 11,
                        cornerRadius: 3,
                        strokeOpacity: 0,
                    }),
                    paddingTop: 3, // Adjust padding to make it more compact
                    paddingBottom: 3,
                    paddingLeft: 6,
                    paddingRight: 6,
                    fontSize: 13, // Adjust font size to fit better
                    x: am5.percent(100), // Position at 100% of plot area width
                    centerX: 1, // Anchor to the right edge
                    y: labelY,
                    centerY: exitPrice < min ? 1 : 0,
                    layer: 100
                });

                valueAxis.chart.plotContainer.children.push(edgeLabel);
                // Store a reference to the label so we can remove it later
                valueAxis.chart.plotContainer.setPrivate("bustMaxPriceEdgeLabel", edgeLabel);
            }
        }
    };

    const removeBustMaxPriceLine = (valueAxis) => {
        // Remove any previous bust price range or label
        valueAxis.axisRanges.each((range) => {
            if (range.get("id") === "bustMaxPriceRange") {
                valueAxis.axisRanges.removeValue(range);
            }
        });

        // Remove any existing edge labels
        if (valueAxis.chart.plotContainer.getPrivate("bustMaxPriceEdgeLabel")) {
            valueAxis.chart.plotContainer.children.removeValue(
                valueAxis.chart.plotContainer.getPrivate("bustMaxPriceEdgeLabel")
            );
            valueAxis.chart.plotContainer.setPrivate("bustMaxPriceEdgeLabel", null);
        }
    };

    const updateBustPriceLine = (selectedBet, valueAxis, root) => {
        // Collect ranges to remove
        const rangesToRemove = [];

        valueAxis.axisRanges.each((range) => {
            if (range && range.get("id") === "bustPriceRange") {
                rangesToRemove.push(range);
            }
        });

        // Remove collected ranges
        rangesToRemove.forEach((range) => {
            valueAxis.axisRanges.removeValue(range);
        });

        // Remove any existing edge labels
        const existingEdgeLabel = valueAxis.chart.plotContainer.getPrivate("bustPriceEdgeLabel");
        if (existingEdgeLabel) {
            valueAxis.chart.plotContainer.children.removeValue(existingEdgeLabel);
            valueAxis.chart.plotContainer.setPrivate("bustPriceEdgeLabel", null);
        }

        if (selectedBet) {
            const bustPrice = selectedBet.bustPrice;
            const bustPriceColor = colors?.lossColor || am5.color(0xff4560); // Customize this as per your need

            const min = valueAxis.getPrivate("selectionMin");
            const max = valueAxis.getPrivate("selectionMax");

            if (bustPrice >= min && bustPrice <= max) {
                // Bust price is within the visible range
                let bustPriceRange = valueAxis.createAxisRange(
                    valueAxis.makeDataItem({
                        value: bustPrice,
                        id: "bustPriceRange" // Assign an id to easily identify this range later
                    })
                );

                let bustPriceLabel = bustPriceRange.get("label");
                if (bustPriceLabel) {
                    bustPriceLabel.setAll({
                        fill: am5.color(0x000000),
                        background: am5.PointedRectangle.new(root, {
                            fill: bustPriceColor,
                            pointerLength: 10,
                            pointerBaseWidth: 40,
                            pointerX: -6,
                            pointerY: 10,
                            cornerRadius: 3,
                            strokeOpacity: 0,
                        }),
                        padding: 5,
                        paddingLeft: 5,
                        fontSize: 13, // Adjust font size
                        text: 'Bust Price',
                        layer: 100
                    });
                }

                // Customize the appearance of the bust price line
                bustPriceRange.get("grid").setAll({
                    visible: true,
                    stroke: bustPriceColor,
                    strokeWidth: 2,
                    strokeOpacity: 1,
                    location: 1,
                    layer: 100
                });
            } else {
                // Bust price is out of view, place a label at the right edge
                let axisLength = valueAxis.get('renderer').axisLength();
                let labelY = bustPrice < min ? axisLength : 0;

                // Adjust labelY to ensure the label stays in bounds at the bottom
                if (labelY > axisLength - 22) {
                    labelY = axisLength - 22;  // Push it upwards to ensure it's not out of bounds
                }

                let edgeLabel = am5.Label.new(root, {
                    text: "Bust Price",
                    fill: am5.color(0x000000), // Text color
                    background: am5.PointedRectangle.new(root, { // Using a rectangle instead of a PointedRectangle
                        fill: bustPriceColor,
                        pointerLength: 15,
                        pointerBaseWidth: 50,
                        pointerX: -6,
                        pointerY: 11,
                        cornerRadius: 3,
                        strokeOpacity: 0,
                    }),
                    paddingTop: 3, // Adjust padding to make it more compact
                    paddingBottom: 3,
                    paddingLeft: 6,
                    paddingRight: 6,
                    fontSize: 13, // Adjust font size to fit better
                    x: am5.percent(100), // Position at 100% of plot area width
                    centerX: 1, // Anchor to the right edge
                    y: labelY,
                    centerY: bustPrice < min ? 1 : 0,
                    layer: 100
                });

                valueAxis.chart.plotContainer.children.push(edgeLabel);
                // Store a reference to the label so we can remove it later
                valueAxis.chart.plotContainer.setPrivate("bustPriceEdgeLabel", edgeLabel);
            }
        }
    };


    const removeBustPriceLine = (valueAxis) => {
        // Remove any previous bust price range or label
        valueAxis.axisRanges.each((range) => {
            if (range.get("id") === "bustPriceRange") {
                valueAxis.axisRanges.removeValue(range);
            }
        });

        // Remove any existing edge labels
        if (valueAxis.chart.plotContainer.getPrivate("bustPriceEdgeLabel")) {
            valueAxis.chart.plotContainer.children.removeValue(
                valueAxis.chart.plotContainer.getPrivate("bustPriceEdgeLabel")
            );
            valueAxis.chart.plotContainer.setPrivate("bustPriceEdgeLabel", null);
        }
    };

    useEffect(() => {
        if (!selectedBet || selectedBet.coin !== coin) {
            setSelectedBet(null);
        }
    }, [coin]);

    // Throttle the handleFrameEnded to reduce calls per frame
    const handleFrameEnded = useCallback(throttle(() => {
        if (!chartRef.current) return;

        const { valueAxis, root } = chartRef.current;

        const visibleMin = valueAxis.getPrivate("selectionMin");
        const visibleMax = valueAxis.getPrivate("selectionMax");

        // Check if the visible range has changed before proceeding
        if (previousMinMax.current.min !== visibleMin || previousMinMax.current.max !== visibleMax) {
            previousMinMax.current = { min: visibleMin, max: visibleMax }; // Update the stored range

            // Now you can efficiently check the bust price against the updated range
            if (selectedBet && selectedBet.coin === coin) {
                updateBustPriceLine(selectedBet, valueAxis, root);
                updateBustMaxPriceLine(selectedBet, valueAxis, root);
            } else if (selectedBet && selectedBet.coin !== coin) {
                navigate(`/${selectedBet.coin.replace(/USDT$/, '')}`);
            } else {
                removeBustPriceLine(valueAxis);
                removeBustMaxPriceLine(valueAxis);
            }
        }
    }, 100), [selectedBet]);

    useEffect(() => {
        if (!chartRef.current) return;

        const { valueAxis, root } = chartRef.current;

        // Listen to frameended for detecting range changes
        root.events.on("frameended", handleFrameEnded);

        // Ensure bust price line is updated when selectedBet changes
        if (selectedBet && selectedBet.coin === coin) {
            updateBustPriceLine(selectedBet, valueAxis, root);
            updateBustMaxPriceLine(selectedBet, valueAxis, root);
        } else if (selectedBet && selectedBet.coin !== coin) {
            navigate(`/${selectedBet.coin.replace(/USDT$/, '')}`);
        } else {
            removeBustPriceLine(valueAxis);
            removeBustMaxPriceLine(valueAxis);
        }

        // Cleanup function to remove the event listener and throttle
        return () => {
            root.events.off("frameended", handleFrameEnded);
            handleFrameEnded.cancel(); // Cancel the throttled function
        };
    }, [selectedBet]);

    // Effect to update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1023);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Swipe handlers
    const handlers = useSwipeable(isMobile ? {
        onSwipedLeft: showStatistics ? null : () => handleStatistics(), // Show statistics when swiped left
        onSwipedRight: showStatistics ? () => handleStatistics() : null, // Hide statistics when swiped right
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Allows swipe detection on desktop with mouse drag
    } : {});

    const handleOpenSelector = useCallback((componentName) => {
        setOpenSelector((prevState) => (prevState === componentName ? null : componentName));
    }, []);

    const mapTimerangepick = (newTimerangepick) => {
        const mappings = {
            900000: 5000,
            1800000: 15000,
            3600000: 30000,
            7200000: 300000,
            14400000: 900000,
            43200000: 1800000,
            129600000: 3600000,
            388800000: 14400000,
            1166400000: 43200000
        };
        return mappings[newTimerangepick] || newTimerangepick; // Default to original if no mapping found
    };

    // Transform the timerangepick value
    const transformedTimerangepick = useMemo(() => mapTimerangepick(timerangepick), [timerangepick]);

    const newShade = useCallback((hexColor, magnitude) => {
        if (!hexColor || typeof hexColor !== 'string') {
            console.error('Invalid hexColor provided:', hexColor);
            return '#000000'; // Default color
        }

        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
        } else {
            return hexColor;
        }
    }, []);

    useLayoutEffect(() => {
        const getData = async () => {
            try {
                // Fetch OHLC data
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/crypto/ohlcPrices/${coin}/${transformedTimerangepick}`);
                const ohlcData = response.data.map(item => {
                    const coinData = item.pricesohlc[coin];
                    if (coinData) {
                        return {
                            time: new Date(item.timestamp).getTime() / 1000,
                            open: coinData.open,
                            high: coinData.high,
                            low: coinData.low,
                            close: coinData.close,
                        };
                    }
                    return null;
                }).filter(item => item !== null);

                if (ohlcData.length === 0) return;

                /*                 // **NEW:** Integrate pending currentPrice updates
                                if (pendingPricesRef.current.length > 0) {
                                    // Sort pending prices by timestamp to maintain chronological order
                                    pendingPricesRef.current.sort((a, b) => a.timestamp - b.timestamp);
                
                                    pendingPricesRef.current.forEach(({ price, timestamp }) => {
                                        // Determine the candle interval
                                        const interval = parseInt(transformedTimerangepick, 10);
                                        const candleTime = Math.floor(timestamp / interval) * interval;
                                        console.log(candleTime)
                
                                        // Check if the pending price falls within the last candle's interval
                                        const lastCandle = ohlcData[ohlcData.length - 1];
                                        if (lastCandle && lastCandle.time === candleTime) {
                                            // Update the last candle's close, high, and low
                                            lastCandle.close = price;
                                            lastCandle.high = Math.max(lastCandle.high, price);
                                            lastCandle.low = Math.min(lastCandle.low, price);
                                        } else {
                                            // Create a new candle with open, high, low, close all set to the pending price
                                            ohlcData.push({
                                                time: candleTime,
                                                open: price,
                                                high: price,
                                                low: price,
                                                close: price,
                                            });
                                        }
                                    });
                
                                    // Clear the pending prices after integration
                                    pendingPricesRef.current = [];
                                } */

                // Update state with the integrated data
                setChartData(ohlcData);
                setChartDataAvailable(true);
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        };

        // Dispose existing chart if any
        if (rootRef.current) {
            rootRef.current.dispose();
            rootRef.current = null;
        }

        const getStatistics = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/crypto/prices/${coin}`);
                const data = response.data.data.map(item => item.value);
                setGraphData(data);
            } catch (error) {
                console.error(error);
            }
        };

        getData();
        getStatistics();
    }, []);

    useEffect(() => {
        if (chartDataAvailable && pendingPricesRef.current.length > 0) {
            // Already integrated in getData, ensure it's cleared
            pendingPricesRef.current = [];
        }
    }, [chartDataAvailable]);

    // Send a message to the backend to request historical OHLC data
    useEffect(() => {
        if (coin) {
            sendMessage({ type: 'requestOHLC', coin, timerangepick: transformedTimerangepick });
        }
    }, []);

    const aggregateData = () => {
        const relevantData = dataBuffer.current;

        if (relevantData.length === 0) return null;

        const open = relevantData[0].value;
        const close = relevantData[relevantData.length - 1].value;
        const high = Math.max(...relevantData.map(d => d.value));
        const low = Math.min(...relevantData.map(d => d.value));

        return { open, high, low, close };
    };

    const updateCandlestick = () => {
        const candlestick = {
            date: lastCandleTime.current,
            open: lastCandle.current ? lastCandle.current.open : currentPrice,
            high: lastCandle.current ? Math.max(lastCandle.current.high, currentPrice) : currentPrice,
            low: lastCandle.current ? Math.min(lastCandle.current.low, currentPrice) : currentPrice,
            close: currentPrice
        };

        if (chartRef?.current?.series.dataItems.length > 0) {
            const lastDataItem = chartRef.current.series.dataItems[chartRef.current.series.dataItems.length - 1];
            const previousPrice = lastDataItem.get("valueY");

            if (currentPrice > previousPrice) {
                setPriceDirection('up');
            } else if (currentPrice < previousPrice) {
                setPriceDirection('down');
            }
        }

        lastCandle.current = candlestick;

        const index = chartRef?.current?.series?.dataItems.length - 1;

        if (index >= 0) {
            chartRef?.current?.series?.data.setIndex(index, candlestick);
        } else {
            chartRef?.current?.series?.data.push(candlestick);
        }

        const backgroundColor = candlestick.close > candlestick.open ? colors?.profitColor : colors?.lossColor;

        // Update the current price label
        if (chartRef?.current?.currentValueDataItem) {
            chartRef.current.currentValueDataItem.set("value", currentPrice);
            const currentLabel = chartRef.current.currentValueDataItem.get("label");
            currentLabel.set("text", getFormattedValue(currentPrice));
            if (currentLabel) {
                currentLabel.setAll({
                    fill: am5.color('#000000'),
                    layer: 50,
                    background: am5.PointedRectangle.new(chartRef.current.root, {
                        fill: am5.color(backgroundColor),
                        pointerLength: 10, // Length of the pointer
                        pointerBaseWidth: 40, // Width of the pointer base
                        pointerX: -6,
                        pointerY: 10,
                        cornerRadius: 3,
                        strokeOpacity: 0,
                    }),
                    padding: 5,
                    paddingLeft: 5,
                    fontSize: 13, // Adjust font size
                });
            }

            // Update the horizontal dotted line
            const currentGrid = chartRef.current.currentValueDataItem.get("grid");
            if (currentGrid) {
                currentGrid.animate({
                    key: "strokeDasharray",
                    to: [6, 5],
                    duration: 500,
                    easing: am5.ease.linear
                });
                currentGrid.setAll({
                    strokeOpacity: 1,
                    stroke: am5.color(backgroundColor),
                    visible: true
                });
            }
        }
    };

    useEffect(() => {
        if (currentPrice !== null && currentTime !== null) {
            const now = currentTime;

            if (!chartDataAvailable) {
                // **NEW:** Store the currentPrice and its timestamp
                pendingPricesRef.current.push({ price: currentPrice, timestamp: now });
            } else {
                // Run this part only on load
                if (!lastCandle.current && currentOHLC && currentOHLC.length > 0) {
                    const lastChartData = chartData[chartData.length - 1];

                    // Check if the last item in chartData is more than the current interval away
                    if (lastChartData && (now - lastChartData.time >= transformedTimerangepick)) {
                        if (currentOHLC.length > 1) {
                            const secondLastOHLC = currentOHLC[currentOHLC.length - 2];
                            const lastOHLC = currentOHLC[currentOHLC.length - 1];

                            // Push the second last OHLC
                            const secondLastCandle = {
                                date: secondLastOHLC.timestamp,
                                open: secondLastOHLC.open,
                                high: secondLastOHLC.high,
                                low: secondLastOHLC.low,
                                close: secondLastOHLC.close
                            };

                            if (chartRef?.current?.series) {
                                chartRef.current.series.data.push(secondLastCandle);
                            }

                            // Update lastCandleTime to the second last OHLC timestamp
                            lastCandleTime.current = secondLastOHLC.timestamp;

                            // Push the last OHLC data
                            lastCandle.current = {
                                date: lastOHLC.timestamp,
                                open: lastOHLC.open,
                                high: lastOHLC.high,
                                low: lastOHLC.low,
                                close: currentPrice // Initialize close with the current live price
                            };

                            if (chartRef?.current?.series) {
                                chartRef.current.series.data.push(lastCandle.current);
                            }

                            // Update lastCandleTime to the last OHLC timestamp
                            lastCandleTime.current = lastOHLC.timestamp;
                        } else if (currentOHLC.length === 1) {
                            // If only one OHLC data point is available
                            const ohlcData = currentOHLC[currentOHLC.length - 1];
                            lastCandleTime.current = ohlcData.timestamp;
                            lastCandle.current = {
                                date: ohlcData.timestamp,
                                open: ohlcData.open,
                                high: ohlcData.high,
                                low: ohlcData.low,
                                close: currentPrice // Initialize close with the current live price
                            };

                            if (chartRef?.current?.series) {
                                chartRef.current.series.data.push(lastCandle.current);
                            }
                        }
                    }
                }

                // Add current price to data buffer
                dataBuffer.current.push({ date: now, value: currentPrice });

                // Check if the interval has passed since the last candle
                if (now - lastCandleTime.current >= transformedTimerangepick) {
                    const candlestick = aggregateData();
                    const MAX_CANDLES = 10000;
                    if (candlestick && chartRef.current) {
                        const finalCandle = {
                            ...candlestick,
                            date: lastCandleTime.current + transformedTimerangepick
                        }; // Add interval to timestamp
                        chartRef.current.series.data.push(finalCandle);
                        lastCandleTime.current += transformedTimerangepick; // Update the last candle time
                        dataBuffer.current = [];

                        if (chartRef.current.series.data.length > MAX_CANDLES) {
                            // Remove the oldest candle (at index 0)
                            chartRef.current.series.data.removeIndex(0);
                        }

                        // Start a new candle with current price for all values
                        lastCandle.current = {
                            date: lastCandleTime.current,
                            open: currentPrice,
                            high: currentPrice,
                            low: currentPrice,
                            close: currentPrice
                        };

                        // Ensure the new candle is updated correctly
                        updateCandlestick();
                    }
                } else {
                    // Update candlestick with the latest price data
                    updateCandlestick();
                }
            }
        }
    }, [currentPrice, chartData, currentOHLC]);


    const getTimeUnitAndCount = useCallback((transformedTimerangepick) => {
        if (transformedTimerangepick <= 60000) {
            return { timeUnit: 'second', count: transformedTimerangepick / 1000 };
        } else if (transformedTimerangepick <= 1800000) {
            return { timeUnit: 'minute', count: transformedTimerangepick / 60000 };
        } else if (transformedTimerangepick <= 14400000) {
            return { timeUnit: 'hour', count: transformedTimerangepick / 3600000 };
        } else {
            return { timeUnit: 'hour', count: transformedTimerangepick / 3600000 }; // Adjust as needed for larger intervals
        }
    }, []);

    const getZoomCounts = useCallback((timeUnit) => {
        let minZoomCount;
        let maxZoomCount;

        switch (timeUnit) {
            case 'second':
                minZoomCount = 9;
                maxZoomCount = 450;
                break;
            case 'minute':
                minZoomCount = 10;
                maxZoomCount = 60;
                break;
            case 'hour':
                minZoomCount = 3;
                maxZoomCount = 10;
                break;
            default:
                minZoomCount = 9;
                maxZoomCount = 450;
                break;
        }

        return { minZoomCount, maxZoomCount };
    }, []);

    const addBulletToChart = useCallback((series, root, bet) => {
        const publicBets = JSON.parse(localStorage.getItem('publicBets'));
        const myBets = JSON.parse(localStorage.getItem('myBets'));
        const userId = user ? user._id : null;

        // Get the latest data item
        const dataItem = series.dataItems[series.dataItems.length - 1];

        // Only proceed if we have a valid dataItem
        if (dataItem) {
            let shouldDisplayBet = false;

            // Determine whether to display the bet based on publicBets and myBets settings
            if (publicBets && !myBets) {
                // Show all public bets except those that belong to the user
                shouldDisplayBet = bet.userId !== userId;
            } else if (!publicBets && myBets) {
                // Show only the user's own bets
                shouldDisplayBet = bet.userId === userId;
            } else if (publicBets && myBets) {
                // Show all bets (both public and user's own bets)
                shouldDisplayBet = true;
            }

            // If both publicBets and myBets are false, shouldDisplayBet remains false, so nothing is shown.

            if (shouldDisplayBet && bet.coin === coin) {
                const container = am5.Container.new(series.root, {
                    layout: root.verticalLayout,
                    layer: 100
                });

                // Add rounded rectangle and labels for additional info
                const rectContainer = container.children.push(am5.Container.new(series.root, {
                    layout: root.verticalLayout,
                    centerX: am5.percent(0),
                    y: am5.percent(-50),
                }));

                rectContainer.children.push(am5.Label.new(root, {
                    html: `
                        <div style="background-color: ${colors?.secondaryColorBG}; border-radius: 5px; padding: 5px 10px;">
                        <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 5px;">
                        <img src="${process.env.REACT_APP_FRONTEND_URL}/images/avatar/${bet?.avatar}" width="25" height="25" style="margin-right: 10px"/>
                        <span style="font-weight: 700;font-size: 13px;">${bet?.username.slice(0, 12)}</span>
                        ${bet?.direction === "up" ? `<i class="fa fa-angle-double-up" style="margin-left:5px; color:${colors?.profitColor}; font-size: 14px; margin-top: 2px;" aria-hidden="true"></i>` : `<i class="fa fa-angle-double-down" style="margin-left:5px; color:${colors?.lossColor}; font-size: 14px; margin-top: 2px;" aria-hidden="true"></i>`}
                        </div>
                        <div style="display: flex; align-items: center; justify-content: start;">
                        <span style="padding-right:10px; font-weight: 700;font-size: 12px;">P&L</span> <strong><p style="${bet?.profitOrLoss > 0 ? `color:${colors?.profitColor}` : `color:${colors?.lossColor}`}; font-weight: 700;font-size: 12px;">${bet?.profitOrLoss < 0 ? `-$${Math.abs(bet?.profitOrLoss).toFixed(2)}` : `$${bet?.profitOrLoss.toFixed(2)}`}</p></strong>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: start;">
                        <span style="padding-right:13px; font-weight: 700;font-size: 12px;">ROI</span> <strong><p style="${bet?.profitOrLoss > 0 ? `color:${colors?.profitColor}` : `color:${colors?.lossColor}`}; font-weight: 700;font-size: 12px;">${(bet?.roi).toFixed(2)}%</p></strong>
                        </div>
                        </div>
                            `,
                    fill: am5.color("#FFF"),
                    x: am5.percent(50),
                    y: 0,
                    centerX: am5.percent(50),
                }));

                const bullet = am5.Bullet.new(series.root, {
                    sprite: container,
                });

                series.addBullet(dataItem, bullet);

                // Set a timeout to remove the bullet after 5 seconds
                setTimeout(() => {
                    bullet.dispose(); // Properly dispose of the bullet
                    series.bullets.removeValue(bullet); // Remove the bullet from the series
                }, 5000); // 5000 milliseconds = 5 seconds
            }
        };
    }, [coin, colors?.secondaryColorBG, colors?.profitColor, colors?.lossColor]);

    useEffect(() => {
        if (chartRef.current && publicBets.length > 0) {
            const { series, root } = chartRef.current;
            const latestBet = publicBets[0];

            // Skip the initial load
            if (initialLoad.current) {
                initialLoad.current = false;
                return;
            }

            addBulletToChart(series, root, latestBet);
        }
    }, [publicBets, chartRef.current]);

    useLayoutEffect(() => {
        if (!chartRef.current) return;

        if (rootRef.current) {
            rootRef.current.dispose();
            rootRef.current = null;
        }

        if (chartData.length === 0) return;

        const { timeUnit, count } = getTimeUnitAndCount(transformedTimerangepick);
        const { minZoomCount, maxZoomCount } = getZoomCounts(timeUnit);

        let root = am5.Root.new(chartRef.current, {
            useSafeResolution: false
        });
        rootRef.current = root;
        root._logo.dispose();

        let stockChart = root.container.children.push(am5stock.StockChart.new(root, {}));
        let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root, {}));

        let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                inside: false,
                minGridDistance: 70,
                opposite: true,
                strokeOpacity: 1,
                stroke: am5.color(newShade(colors?.secondaryColorBG, 50))
            }),
            extraMin: 0.1,
            tooltip: am5.Tooltip.new(root, {
                getFillFromSprite: false,
                layer: 100,
                background: am5.PointedRectangle.new(root, {
                    fill: am5.color(newShade(colors?.secondaryColorBG, 50)),
                    pointerLength: 10,
                    pointerBaseWidth: 30,
                    pointerY: 10,
                    cornerRadius: 3,
                    strokeOpacity: 0,
                    fillOpacity: 1
                }),
                paddingLeft: 5
            }),
            numberFormat: numberFormat,
            extraTooltipPrecision: 1
        }));

        valueAxis.get("renderer").ticks.template.setAll({
            location: 0.5,
            stroke: am5.color(newShade(colors?.secondaryColorBG, 50)),
            strokeWidth: 1,
            strokeOpacity: 1,
            length: 3, // Length of the tick marks
            visible: true
        });

        valueAxis.get("renderer").grid.template.set("visible", false);
        valueAxis.get("renderer").labels.template.setAll({
            fill: am5.color(newShade(colors?.secondaryColorBG, 50)),
            minPosition: 0.01,
            maxPosition: 0.99,
            paddingLeft: 10
        });

        let dateAxis = mainPanel.xAxes.push(am5xy.GaplessDateAxis.new(root, {
            maxDeviation: 0.5,
            extraMax: 0.1,
            extraMin: 0.1,
            minZoomCount: minZoomCount,
            maxZoomCount: maxZoomCount,
            tooltipLocation: timeUnit === 'hour' && count === 1 ? 0.5 : 0,
            baseInterval: { timeUnit: timeUnit, count: count },
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 60,
                strokeOpacity: 1,
                stroke: am5.color(newShade(colors?.secondaryColorBG, 50))
            }),
            tooltip: am5.Tooltip.new(root, {
                dy: -3,
                getFillFromSprite: false,
                background: am5.Rectangle.new(root, {
                    fill: am5.color(newShade(colors?.secondaryColorBG, 50))
                })
            }),
            tooltipDateFormat: "dd/MM\nHH:mm:ss"
        }));

        dateAxis.get("renderer").ticks.template.setAll({
            location: 0.5,
            stroke: am5.color(newShade(colors?.secondaryColorBG, 50)),
            strokeWidth: 1,
            strokeOpacity: 1,
            length: 3, // Length of the tick marks
            visible: true
        });

        dateAxis.get("renderer").grid.template.set("visible", false);
        dateAxis.get("renderer").labels.template.setAll({
            fill: am5.color(newShade(colors?.secondaryColorBG, 50)),
            lineHeight: 1.5
        });

        let series = mainPanel.series.push(am5xy.CandlestickSeries.new(root, {
            valueYField: "close",
            openValueYField: "open",
            lowValueYField: "low",
            highValueYField: "high",
            valueXField: "date",
            xAxis: dateAxis,
            yAxis: valueAxis,
            clustered: false,
            locationX: timeUnit === 'hour' && count === 1 ? 0.5 : 0,
            interpolationDuration: 0
        }));

        series.columns.template.states.create("dropFromOpen", {
            fill: am5.color(colors?.lossColor),
            stroke: am5.color(colors?.lossColor),
        });

        series.columns.template.states.create("riseFromOpen", {
            fill: am5.color(colors?.profitColor),
            stroke: am5.color(colors?.profitColor),
        });

        series.columns.template.setAll({
            width: am5.percent(75)
        });

        series.data.setAll(chartData.map(item => ({
            date: new Date(item.time * 1000).getTime(),
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close
        })));

        // Ensure the axis is ready before setting the start and end
        root.events.on("frameended", adjustAxisRange);

        function adjustAxisRange() {
            if (dateAxis.getPrivate("max") !== undefined && dateAxis.getPrivate("min") !== undefined && chartData.length > 0) {
                // Remove the event listener after adjustment
                root.events.off("frameended", adjustAxisRange);

                const startMultiplier = (transformedTimerangepick !== 43200000 && transformedTimerangepick !== 14400000) ? 40 : 10;
                const endMultiplier = (transformedTimerangepick !== 43200000 && transformedTimerangepick !== 14400000) ? 3 : 1.5;
                const startMultiplierMobile = (transformedTimerangepick !== 43200000 && transformedTimerangepick !== 14400000) ? 7.5 : 4;
                const endMultiplierMobile = 1.5;

                const lastDataPointDate = new Date(chartData[chartData.length - 1].time * 1000).getTime();
                const widthWindow = window.innerWidth;

                dateAxis.zoomToDates(widthWindow > 768 ? new Date(lastDataPointDate - (transformedTimerangepick * startMultiplier)) : new Date(lastDataPointDate - (transformedTimerangepick * startMultiplierMobile)), widthWindow > 768 ? new Date(lastDataPointDate + (transformedTimerangepick * endMultiplier)) : new Date(lastDataPointDate + (transformedTimerangepick * endMultiplierMobile)));
            }
        }

        let cursor = mainPanel.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: dateAxis,
            yAxis: valueAxis,
            snapToSeries: [series],
            snapToSeriesBy: "y!"
        }));
        cursor.lineX.setAll({
            stroke: am5.color(newShade(colors?.secondaryColorBG, 50)),
            strokeWidth: 1,
            strokeDasharray: []
        });
        cursor.lineY.setAll({
            stroke: am5.color(newShade(colors?.secondaryColorBG, 50)),
            strokeWidth: 1,
            strokeDasharray: []
        });
        cursor.lineY.set("visible", true);
        cursor.lineX.set("visible", true);

        let currentValueDataItem = valueAxis.createAxisRange(valueAxis.makeDataItem({ value: currentPrice }));

        if (selectedBet) {
            const newSelectedBet = selectedBet;
            setSelectedBet(null);
            setTimeout(() => setSelectedBet(newSelectedBet), 50);
        }

        chartRef.current = { series, dateAxis, root, currentValueDataItem, valueAxis };

        return () => {
            if (rootRef.current) {
                rootRef.current.dispose();
                rootRef.current = null;
            }
        };
    }, [chartData]);

    const getFormattedValue = useCallback((value) => {
        if (value !== null) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision
            }).format(value);
        }
        return "-";
    }, [precision]);

    const getFormattedValueDisplay = useCallback((value) => {
        if (value !== null) {
            const isIdInList = idsToSubtractOne.includes(coinConfig?.id);
            const adjustedPrecision = isIdInList ? precision + 1 : precision;
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: adjustedPrecision,
                maximumFractionDigits: adjustedPrecision,
            }).format(value);
        }
        return "-";
    }, [coinConfig, precision]);

    const decimalsSize = useCallback((value) => {
        if (value !== null) {
            const parts = value.split('.');
            if (parts.length > 1) {
                return parts;
            }
        }
        return [value, ''];
    }, []);

    const handleCryptopick = useCallback((cryptoName, cryptoSymbol) => {
        if (coin === cryptoName) return; // Do nothing if the same crypto is clicked
        setOpenCryptoList((prev) => !prev);
        navigate(`/${cryptoSymbol}`);
    }, [coin, navigate]);

    const resetZoom = () => {
        if (chartRef.current) {
            const { dateAxis } = chartRef.current;
            if (chartData.length > 0) {
                const lastDataPointTimestamp = currentTime;
                const widthWindow = window.innerWidth;
                dateAxis.zoomToDates(widthWindow > 768 ? new Date(lastDataPointTimestamp - (transformedTimerangepick * 40)) : new Date(lastDataPointTimestamp - (transformedTimerangepick * 7.5)), widthWindow > 768 ? new Date(lastDataPointTimestamp + (transformedTimerangepick * 3)) : new Date(lastDataPointTimestamp + (transformedTimerangepick * 2.5)));
            }
        }
    };

    const handleStatistics = useCallback(() => {
        if (showStatistics) {
            setClosingStatistics(true);
            setTimeout(() => {
                setShowStatistics(false);
                setClosingStatistics(false);
            }, 300);
        } else {
            setShowStatistics(true);
        }
    }, [showStatistics]);

    if (/* loading */!chartDataAvailable || !cryptoDataAvailable) {
        return <Loading />;
    }

    return (
        <div  {...handlers} className="flex flex-col h-full chart-container overflow-y-clip">
            <div className='flex-row items-center justify-between hidden xl:flex gap-x-2'>
                <div className="flex flex-row items-center">
                    <img className="rounded-full size-10" src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coinId}.png`} alt={`${coin}`} />
                    <span className={`flex items-center text-[32px] px-2`}
                        style={{
                            color: priceDirection === 'up' ? colors?.profitColor : colors?.lossColor
                        }}>
                        <span className="text-[28px]">
                            {priceDirection === 'up' && <FaAngleDoubleUp />}
                            {priceDirection === 'down' && <FaAngleDoubleDown />}
                        </span>
                        {currentPrice != null ? (
                            <div className="pl-2 font-black">
                                {decimalsSize(getFormattedValueDisplay(currentPrice))[0]}.
                                <span className="text-[20px]">{decimalsSize(getFormattedValueDisplay(currentPrice))[1]}</span>
                            </div>
                        ) : ''}
                    </span>
                </div>
                <div className="flex flex-row items-center">
                    <div className="flex flex-row items-center p-2 px-3 rounded-md">
                        <TimeRangeSelector
                            isOpen={openSelector === 'timeRange'}
                            toggleOpen={() => handleOpenSelector('timeRange')}
                        />
                    </div>
                    <GraphSettings
                        isOpen={openSelector === 'graphSettings'}
                        toggleOpen={() => handleOpenSelector('graphSettings')}
                    />
                </div>
            </div>
            <div className='flex flex-row justify-between xl:hidden'>
                <div className="relative">
                    <div onClick={() => setOpenCryptoList(!openCryptoList)} className="flex flex-row items-center gap-x-2 p-2.5 px-0 cursor-pointer" style={{
                        backgroundColor: colors?.primaryColorBG,
                    }}>
                        <img className="rounded-full size-[30px] sm:size-10" src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coinId}.png`} alt={`${coin}`} />
                        <span className={`flex items-center text-[32px]`}
                            style={{
                                color: priceDirection === 'up' ? colors?.profitColor : colors?.lossColor
                            }}>
                            <span className="text-[18px] sm:text-[32px]">
                                {priceDirection === 'up' && <FaAngleDoubleUp />}
                                {priceDirection === 'down' && <FaAngleDoubleDown />}
                            </span>
                            {currentPrice != null ? (
                                <div className="font-black text-[24px] sm:text-[32px]">
                                    {decimalsSize(getFormattedValueDisplay(currentPrice))[0]}.
                                    <span className="text-[12px] sm:text-[20px]">{decimalsSize(getFormattedValueDisplay(currentPrice))[1]}</span>
                                </div>
                            ) : ''}
                        </span>
                        <FaCaretDown className="m-2" />
                    </div>
                    {openCryptoList && cryptoList && (
                        <div className="absolute left-0 right-0 z-10 max-w-full overflow-y-auto text-white rounded shadow-lg top-14 max-h-[200px] xl:max-h-96" style={{
                            backgroundColor: colors?.secondaryColorBG,
                        }}>
                            {cryptoList.map((crypto, index) => (
                                (crypto.active ? <button key={index} className="flex flex-row items-center w-full px-3 py-3 gap-x-2 hover:brightness-125" onClick={() => handleCryptopick(crypto.cryptoName, crypto.symbol)}
                                    style={{
                                        backgroundColor: colors?.secondaryColorBG,
                                    }}>
                                    <img className="rounded-full size-6" src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`} alt={`${crypto.name}`} />
                                    <span className="font-black">{crypto.name}</span>
                                </button> : null)
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex flex-row items-center gap-x-2">
                    <TimeRangeSelector
                        isOpen={openSelector === 'timeRange'}
                        toggleOpen={() => handleOpenSelector('timeRange')}
                    />
                    <GraphSettings
                        isOpen={openSelector === 'graphSettings'}
                        toggleOpen={() => handleOpenSelector('graphSettings')}
                    />
                </div>
            </div>
            <div ref={chartRef} className={`relative cursor-crosshair ${showStatistics ? (closingStatistics ? "chartDataOpen" : "chartDataClose") : "flex"}`} style={{ width: "100%", flex: 1 }}>
                {
                    <button onClick={resetZoom} className={`z-[10] absolute bottom-12 right-[90px] bg-[#cbd7ff08] p-2 rounded-md w-12 flex justify-center py-2.5 ${new Date(chartRef.current?.dateAxis?.positionToDate(chartRef.current?.dateAxis?.get("end"))).getTime() > currentTime ? 'hidden' : ''}`}>
                        <FaAngleDoubleRight />
                    </button>
                }
                <div className={`absolute lg:hidden top-1/2 right-0 animate-bouncing ${showStatistics ? "hidden" : "flex"}`}><FaAngleRight className='w-5 h-5' /></div>
            </div>
            <div className={`lg:flex lg:relative top-[130px] pb-[60px] lg:pb-0 h-full lg:h-fit lg:top-0 right-0 left-0 lg:mx-4 ${showStatistics ? (closingStatistics ? "statisticsclose" : "statisticsopening") : "hidden"} min-h-max`}>
                {!isMobile ?
                    <Statistics coin={coin} graphDataInitial={graphData} /> :
                    <StatisticsMobile coin={coin} graphDataInitial={graphData} />
                }
                <div className={`absolute top-[45%] left-0 z-50 animate-bouncing ${showStatistics ? "flex" : "hidden"}`} ><FaAngleLeft className='w-5 h-5' /></div>
            </div>
        </div>
    );
};

export default CandlebarsChart;