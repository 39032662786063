import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { AuthContext } from "../../context/auth.context";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { useWebSocket } from "../../context/WebSocketProvider";
import axios from 'axios';
import cryptoConfig from '../../cryptoConfig.js';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';
import Modalbetmobile from './Modalbet/Modalbet.jsx';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useBetContext } from "../../context/bet.context.jsx";
import { motion, AnimatePresence } from 'framer-motion';
import Portal from '../Portal/Portal.jsx'
import AnimatedNumber from '../AnimatedNumber/AnimatedNumber.jsx';
import { notify } from "../Toast/Toast";

const MobileMenu = ({ coin }) => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const { publicBets, sendMessage, closedBets, activeBets, cryptoData, colors } = useWebSocket();
  const [betLocked, setBetLocked] = useState(false);
  const [activeTab, setActiveTab] = useState('PUBLIC');
  const [openTooltip, setOpenTooltip] = useState(null);
  const tooltipRef = useRef(null);
  const { selectedBet, setSelectedBet } = useBetContext();
  const containerRef = useRef(null);
  const nodeRefs = useRef({});

  const getAnimationProps = () => {
    if (activeTab === 'ACTIVE') {
      return {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: [0, 0.5, 1], x: [100, -10, 0] }, // Synchronized keyframes
        exit: { opacity: [1, 0.5, 0], x: [0, -10, 100] },
        transition: {
          duration: 0.4, // Increased duration for smoother bump
          ease: "easeOut", // Easing function
          times: [0, 0.5, 1], // Timing for each keyframe
        },
      };
    } else {
      return {
        initial: { opacity: 1, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 },
        transition: { duration: 0.5 },
      };
    }
  };

  const handleSelectBet = (bet) => {
    // Deselect if the bet is already selected
    if (selectedBet && selectedBet._id === bet._id) {
      setSelectedBet(null);
    } else {
      setSelectedBet(bet);
    }
  };

  const formatDate = (date) => {
    return format(new Date(date), 'MMM dd, HH:mm:ss');  // Customize the date format as needed
  };

  // Format numeric values for display
  const getFormattedValue = (value, symbol) => {
    if (value !== null) {
      const precision = cryptoConfig[symbol]?.precision || 2;
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }).format(value);
    }
    return "-";
  };

  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2], 'F', 'F']; // For short hex, default alpha to 'FF' (1.0)
      } else if (c.length === 6) {
        c.push('F', 'F'); // If no alpha, default to 1.0
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 24) & 255, (c >> 16) & 255, (c >> 8) & 255, (c & 255) / 255].join(',') + ')';
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpenTooltip(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  useEffect(() => {
    const handleScroll = () => {
      setOpenTooltip(null); // Close the tooltip on scroll
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (isLoggedIn && activeBets.length > 0) {
      setActiveTab('ACTIVE');
    } else if (isLoggedIn && activeBets.length === 0 && closedBets.length > 0) {
      setActiveTab('CLOSED');
    }
  }, [isLoggedIn, activeBets]);

  // Main effect to handle isLoggedIn and coin changes
  useEffect(() => {
    if (!isLoggedIn) {
      setActiveTab('PUBLIC');
    }
  }, [isLoggedIn, coin]);

  const handleCashOut = async (betId, exitPrice, userId) => {
    try {
      // Send the cashout request to the backend
      const response = await axios.patch(process.env.REACT_APP_SERVER_URL + `/bet/cashout`, {
        betId,
        exitPrice,
        userId
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to cash out');
      }

      const updatedBet = response.data;
      if (selectedBet?._id === updatedBet?._id) setSelectedBet(null);
      setBetLocked(false);

      // Send the updated bet result to the WebSocket server
      const wsMessage = {
        type: 'cashedOutBet',
        username: updatedBet.username,
        userId: updatedBet.userId,
        direction: updatedBet.direction,
        amount: updatedBet.amount,
        multiplier: updatedBet.multiplier,
        result: updatedBet.result,
        entryPrice: updatedBet.entryPrice,
        exitPrice: updatedBet.exitPrice,
        avatar: updatedBet.avatar,
        cryptoId: updatedBet.cryptoId,
        bustPrice: updatedBet.bustPrice,
        coin: updatedBet.coin,
        _id: updatedBet._id,
        createdAt: updatedBet.createdAt,
        updatedAt: updatedBet.updatedAt,
        profitOrLoss: updatedBet.profitOrLoss,
        roi: updatedBet.roi
      };

      sendMessage(wsMessage);
    } catch (error) {
      console.error('Error cashing out:', error);
      notify(
        <div className="toast-content">
          <div className="toast-text">
            <div className="toast-details">There was an error cashing out. Please try again.</div>
          </div>
        </div>,
        "error",
        colors
      );
    }
  };

  const roundToTwo = (num) => {
    return Math.round(num * 100) / 100;
  };

  const getCurrentPriceForCoin = (coin) => {
    if (!cryptoData || typeof cryptoData !== 'object') {
      console.error('cryptoData is not an object:', cryptoData);
      return null;
    }
    return cryptoData.data[coin] ? parseFloat(cryptoData.data[coin]) : null;
  };

  const calculateProfitOrLossPercentage = (bet) => {
    const currentPrice = getCurrentPriceForCoin(bet.coin);
    if (!currentPrice) return { profitOrLoss: 0, roi: 0 };

    let percentageChange;

    if (bet.direction === 'up') {
      // For 'up' bet, profit if currentPrice > entryPrice, otherwise loss
      percentageChange = ((currentPrice - bet.entryPrice) / bet.entryPrice) * 100;
    } else if (bet.direction === 'down') {
      // For 'down' bet, profit if currentPrice < entryPrice, otherwise loss
      percentageChange = ((bet.entryPrice - currentPrice) / bet.entryPrice) * 100;
    }

    // Apply the multiplier
    percentageChange = roundToTwo(percentageChange * bet.multiplier);

    // Calculate profit or loss in monetary terms
    let profitOrLoss = roundToTwo((percentageChange / 100) * bet.amount);

    // Cap the loss to the amount betted
    if (profitOrLoss < -bet.amount) {
      profitOrLoss = roundToTwo(-bet.amount);
    }

    return {
      profitOrLoss: profitOrLoss.toFixed(2),
      roi: percentageChange.toFixed(2)
    };
  };

  const checkIfWin = (bet, profitOrLoss) => {
    return profitOrLoss > 0;
  };
  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== 'string') {
      return '#000000'; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  const renderBetList = (bets) => {
    const lastBets = bets.slice(0, 15);

    return (
      <div className="flex-1">
        {lastBets.length === 0 ? (
          <p className="text-sm text-gray-400">
            No bets available.
          </p>
        ) : (
          <AnimatePresence initial={activeTab === 'ACTIVE'}>
            {lastBets.map((bet, index) => {
              const { profitOrLoss, roi } = bet.result === "pending"
                ? calculateProfitOrLossPercentage(bet)
                : {
                  profitOrLoss: Number(bet.profitOrLoss).toFixed(2),
                  roi: Number(bet.roi).toFixed(2),
                };
              const isWin = bet.result === "pending" ? checkIfWin(bet, profitOrLoss) : bet.result === 'win';

              // Allow bet selection only for active bets
              const isActiveBet = activeTab === 'ACTIVE';
              const handleClick = isActiveBet ? () => handleSelectBet(bet) : null;

              // Check if the current bet is the selected one
              const isSelected = selectedBet && selectedBet._id === bet._id && activeTab === 'ACTIVE';

              const animationProps = getAnimationProps();

              return (
                <motion.div
                  key={bet._id}
                  layout // Enable layout animations
                  initial={animationProps.initial}
                  animate={animationProps.animate}
                  exit={animationProps.exit}
                  transition={animationProps.transition}
                  className={`flex items-center justify-between mb-[5px] py-3 px-4`}
                  style={{
                    backgroundColor: isActiveBet ? isSelected ? newShade(colors?.secondaryColorBG, 10) : colors?.secondaryColorBG : colors?.secondaryColorBG,
                  }}
                  onClick={handleClick}
                >
                  <div className="flex items-center space-x-6 text-left">
                    <div className='flex flex-row items-start'>
                      {activeTab === 'PUBLIC' && <img src={`images/avatar/${bet.avatar}`} loading="lazy" alt="avatar" className="w-8 mr-2" />}
                      <div className={`flex flex-col text-nowrap ${activeTab === 'PUBLIC' ? 'w-16' : 'w-16'}`}>
                        {activeTab === 'PUBLIC' ? <span className="text-xs">{bet.username.slice(0, 11)}</span> : <span className="text-xs">Bet</span>}
                        <p className="flex flex-row items-center text-xs text-white gap-x-1">$ {bet.amount?.toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="flex flex-col text-left">
                      <span className="text-xs">P&L:</span>
                      <p className={`text-xs text-nowrap`}
                        style={{
                          color: profitOrLoss > 0 ? colors?.profitColor : profitOrLoss < 0 ? colors?.lossColor : '#b1b6c6',
                        }}>
                        {profitOrLoss < 0 ? `-$ ` : `$ `}
                        <AnimatedNumber
                          value={Math.abs(Number(profitOrLoss))}
                          format={(val) => val.toFixed(2)}
                        />
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center self-end text-left">
                    <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`} loading="lazy" alt="coin" className="w-5 h-5 rounded-full mr-0.5" />
                    <p className={`text-base pr-0.5`}>{bet.direction === 'up' ? <FaAngleDoubleUp style={{ color: colors?.profitColor }} /> : <FaAngleDoubleDown style={{ color: colors?.lossColor }} />}</p>
                    {
                      activeTab === 'CLOSED' && (
                        bet.result !== 'busted' ? (
                          <div className="flex flex-row items-center pl-2 text-left">
                            <p className='px-2.5 py-1 text-[11px] text-gray-400 bg-[#2b2d3a] rounded-md' style={{
                              backgroundColor: newShade(colors?.mainColorBG, 20),
                            }}>closed</p>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center pl-2 text-left">
                            <p className='px-2.5 py-1 text-[11px] rounded-md' style={{
                              color: colors?.lossColor,
                              backgroundColor: colors?.lossColor + '21',
                            }}>busted</p>
                          </div>
                        )
                      )
                    }
                    {
                      activeTab === 'ACTIVE' && (
                        <div className="flex flex-row items-center self-end pl-2 text-left">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the bet from being selected
                              handleCashOut(bet._id, getCurrentPriceForCoin(bet.coin), user?._id);
                            }}
                            className={`px-2.5 py-1 text-xs rounded-md`}
                            style={{
                              backgroundColor: betLocked ? '#2b2d3a' : isWin ? colors?.profitColor + '21' : colors?.lossColor + '21',
                              color: betLocked ? '#b1b6c6' : isWin ? colors?.profitColor : colors?.lossColor,
                              cursor: betLocked ? 'not-allowed' : 'pointer',
                            }}
                          >
                            CLOSE
                          </button>
                        </div>
                      )
                    }
                    <div className="flex flex-row items-center pl-4 text-left" ref={openTooltip === bet._id ? tooltipRef : null}>
                      <MdOutlineKeyboardArrowDown onClick={() => setOpenTooltip(openTooltip === bet._id ? null : bet._id)} data-tooltip-id={`betInfo-${bet._id}`} data-tooltip-place="top" className="text-xl text-gray-400 cursor-pointer select-none hover:text-gray-600" />
                      {openTooltip && (
                        <Portal>
                          <Tooltip id={`betInfo-${bet._id}`} openOnClick={true} clickable={false} isOpen={openTooltip === bet._id} opacity={1} className="tooltip-custom">
                            <div className="flex flex-col space-y-4 text-xs text-left shadow-2xl p-7" style={{
                              backgroundColor: colors?.secondaryColorBG,
                            }}>
                              <div className='flex flex-row items-center'>
                                <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`} loading="lazy" alt="coin" className="w-5 h-5 mr-1 rounded-full" />
                                <p className={`text-base mr-2`}>{bet.direction === 'up' ? <FaAngleDoubleUp style={{ color: colors?.profitColor }} /> : <FaAngleDoubleDown style={{ color: colors?.lossColor }} />}</p>
                                {activeTab === 'ACTIVE' ? <p className='text-xs font-bold text-gray-400 uppercase'>{formatDate(bet.createdAt)}</p> : <p className='text-xs font-bold text-gray-400 uppercase'>{formatDate(bet.updatedAt)}</p>}
                              </div>
                              {activeTab === 'PUBLIC' && <div className="flex items-center text-left">
                                <img src={`images/avatar/${bet.avatar}`} loading="lazy" alt="avatar" className="w-8 h-8 mr-2 rounded-full" />
                                <div className='flex flex-col items-start'>
                                  <span className="text-sm font-bold">{bet.username.slice(0, 10)}</span>
                                </div>
                              </div>}
                              <div className='flex flex-row space-x-4'>
                                <div className='flex flex-col space-y-4'>
                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Entry Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.entryPrice, bet.coin)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Wager Amount</p>
                                    <p className='text-sm font-medium'>{bet.amount.toFixed(2)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Multiplier</p>
                                    <p className='text-sm font-medium'>x{bet.multiplier.toFixed(2)}</p>
                                  </div>

                                  {bet.result !== 'pending' && <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Exit Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.exitPrice, bet.coin)}</p>
                                  </div>}
                                </div>

                                <div className='flex flex-col space-y-4'>
                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Bust Price</p>
                                    <p className='text-sm font-medium'>{getFormattedValue(bet.bustPrice, bet.coin)}</p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Profit & Loss</p>
                                    <p className={`text-sm font-medium`}
                                      style={{
                                        color: profitOrLoss > 0 ? colors?.profitColor : profitOrLoss < 0 ? colors?.lossColor : '#b1b6c6',

                                      }}>
                                      {profitOrLoss < 0 ? `-$ ` : `$ `}
                                      <AnimatedNumber
                                        value={Math.abs(Number(profitOrLoss))}
                                        format={(val) => val.toFixed(2)}
                                      />
                                    </p>
                                  </div>

                                  <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>ROI</p>
                                    <p className={`text-sm font-medium`}
                                      style={{
                                        color: roi > 0 ? colors?.profitColor : roi < 0 ? colors?.lossColor : '#b1b6c6',
                                      }}>
                                      {roi < 0 ? `-` : ``}
                                      <AnimatedNumber
                                        value={Math.abs(Number(roi))}
                                        format={(val) => val.toFixed(2)}
                                      />
                                      %</p>
                                  </div>

                                  {bet.result !== 'pending' && <div className='space-y-1'>
                                    <p className='font-bold text-gray-400 uppercase'>Status</p>
                                    <p className={`capitalize font-medium text-sm`}
                                      style={{
                                        color: bet.result === 'busted' && colors?.lossColor,
                                      }}>{bet.result === 'busted' ? 'busted' : 'cashed out'}</p>
                                  </div>}
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </Portal>
                      )}
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </AnimatePresence>
        )}
      </div>
    );
  };

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className="lg:hidden flex flex-col h-full text-[#b1b6c6] pb-4">
      <div className="flex flex-col flex-1 p-0 pt-0 pb-0">
        <div className="flex mb-6 gap-x-5">
          <div className='flex justify-between w-full'>
            <div className='flex gap-x-5'>
              {open && <Modalbetmobile open={open} setOpen={setOpen} coin={coin} />}
              {isLoggedIn && (
                <>
                  <button
                    onClick={() => setActiveTab('ACTIVE')}
                    className={`text-[13px] transition-all duration-200 `}
                    style={{
                      color: activeTab === 'ACTIVE' ? colors?.profitColor : "#b1b6c6",
                      textShadow: activeTab === 'ACTIVE' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
                    }}
                  >
                    ACTIVE
                  </button>
                  <button
                    onClick={() => setActiveTab('CLOSED')}
                    className={`text-[13px] transition-all duration-200`}
                    style={{
                      color: activeTab === 'CLOSED' ? colors?.profitColor : "#b1b6c6",
                      textShadow: activeTab === 'CLOSED' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
                    }}
                  >
                    CLOSED
                  </button>
                </>)}
              <button
                onClick={() => setActiveTab('PUBLIC')}
                className={`text-[13px] transition-all duration-200`}

                style={{
                  color: activeTab === 'PUBLIC' ? colors?.profitColor : "#b1b6c6",
                  textShadow: activeTab === 'PUBLIC' ? `0px 0px 8px ${colors?.profitColor}` : 'none',
                }}
              >
                PUBLIC
              </button>
            </div>
            <button className='p-1.5 px-4 text-black text-sm uppercase font-medium transition- duration-200 hover:brightness-110 rounded-md  disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleOpen()}
              style={{
                backgroundColor: colors?.secondaryButton?.mainColor,
                boxShadow: `0px 0px 10px ${hexToRgbA(colors?.secondaryButton?.mainColor + "66")}, 0px 1px 0px rgba ${hexToRgbA(newShade(colors?.mainButton?.mainColor, 40))} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${colors?.secondaryButton?.mainColor} inset`,
              }}>
              NEW BET
            </button>
          </div>
        </div>

        <div ref={containerRef} className="flex-1 overflow-x-hidden overflow-y-auto min-h-[178px] max-h-[178px]">
          {activeTab === 'PUBLIC' && renderBetList(publicBets)}
          {activeTab === 'ACTIVE' && renderBetList(activeBets)}
          {activeTab === 'CLOSED' && renderBetList(closedBets)}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;