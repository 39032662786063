import "./App.css";
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Navbar from "./components/Navbar/Navbar";
import CasinoStatistics from "./components/CasinoDashboard/CasinoDashboard";

import IsPrivate from "./components/IsPrivate/IsPrivate";
/* import IsAnon from "./components/IsAnon/IsAnon";
import IsAdmin from "./components/IsAdmin/IsAdmin"; */
import IsCasino from "./components/IsCasino/IsCasino";
import CasinoCryptocoins from "./components/CasinoDashboard/CasinoCryptocoins";
import CasinoUsers from "./components/CasinoDashboard/CasinoUsers";
import CasinoBots from "./components/CasinoDashboard/CasinoBots";
import CasinoBets from "./components/CasinoDashboard/CasinoBets";
import CasinoColors from "./components/CasinoDashboard/CasinoColors";
import CasinoSettings from "./components/CasinoDashboard/CasinoSettings";
import CasinoColorsEdit from "./components/CasinoDashboard/CasinoColorsEdit";

function App() {

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the initial value
    handleResize();

    // Recalculate on resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App scrollbar">
      <Navbar />

      <Routes>
        <Route path="/" element={<Navigate to="/BTC" />} />
        <Route path="/:coin" element={<HomePage />} />
        <Route
          path="/casino/dashboard/"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoStatistics />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/crypto-coins"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoCryptocoins />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/users"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoUsers />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/bots"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBots />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/bets"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBets />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/colors"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColors />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/colors/edit"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColorsEdit />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/settings"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoSettings />
              </IsCasino>
            </IsPrivate>
          }
        />

        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />

      </Routes>
      <ToastContainer className="mr-5" />
    </div>
  );
}

export default App;
