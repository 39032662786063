import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, containerId = 'portal-root' }) => {
  let container = document.getElementById(containerId);

  // If the container doesn't exist, create it and append to body
  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', containerId);
    document.body.appendChild(container);
  }

  return ReactDOM.createPortal(children, container);
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  containerId: PropTypes.string,
};

export default Portal;
