import { useState, useContext } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { AuthContext } from "../../context/auth.context";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { useWebSocket } from "../../context/WebSocketProvider";
import { IoMdClose } from "react-icons/io";

export default function ModalLoginReg({ open, setOpen, mode }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usernameReg, setUsernameReg] = useState("");
  const [emailReg, setEmailReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");
  const [ModeChange, setModeChange] = useState(mode);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [errorMessageReg, setErrorMessageReg] = useState(undefined);
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false); // State to manage closing animation
  const { colors } = useWebSocket();

  const { storeToken, authenticateUser } = useContext(AuthContext);

  const handleMode = (modeText) => {
    setModeChange(modeText);
  };

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const requestBody = { email, password };

    try {
      const response = await authService.login(requestBody);
      storeToken(response.data.authToken);

      const user = await authenticateUser();

      if (user.role === "casino") navigate("/casino/dashboard");
      if (user.role === "admin") navigate("/admin-dashboard");
      setOpen(false);
    } catch (error) {
      const errorDescription = error.response.data.message;
      setErrorMessage(errorDescription);
    }
  };

  const handleOpenWithAnimation = () => {
    if (open) {
      // If modal is open, initiate close animation
      setIsClosing(true);
    } else {
      setOpen(true); // Directly open the modal
    }
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsClosing(false); // Reset closing state
      setOpen(false); // Actually close the modal after animation
    }
  };

  const handleUsernameReg = (e) => setUsernameReg(e.target.value);
  const handleEmailReg = (e) => setEmailReg(e.target.value);
  const handlePasswordReg = (e) => setPasswordReg(e.target.value);

  const handleSignupSubmit = (e) => {
    e.preventDefault();
    // Create an object representing the request body
    const requestBody = { emailReg, passwordReg, usernameReg };

    // Send a request to the server using axios
    /* 
        const authToken = localStorage.getItem("authToken");
        axios.post(
          `${process.env.REACT_APP_SERVER_URL}/auth/signup`, 
          requestBody, 
          { headers: { Authorization: `Bearer ${authToken}` },
        })
        .then((response) => {})
        */

    // Or using a service
    authService
      .signup(requestBody)
      .then((response) => {
        // If the POST request is successful redirect to the login page
        setModeChange("Login");
      })
      .catch((error) => {
        // If the request resolves with an error, set the error message in the state
        const errorDescription = error.response.data.message;
        setErrorMessageReg(errorDescription);
      });
  };

  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2], "F", "F"]; // For short hex, default alpha to 'FF' (1.0)
      } else if (c.length === 6) {
        c.push("F", "F"); // If no alpha, default to 1.0
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [
          (c >> 24) & 255,
          (c >> 16) & 255,
          (c >> 8) & 255,
          (c & 255) / 255,
        ].join(",") +
        ")"
      );
    }
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <Dialog
      open={open}
      className="relative z-10"
      onClose={() => handleOpenWithAnimation()}
    >
      <DialogBackdrop
        className={`fixed inset-0 transition-opacity ${
          isClosing ? "betbackdropclose" : "betbackdropopen"
        }`}
        style={{
          backgroundColor: colors?.mainColorBG + "90",
        }}
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            className={`relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all w-[90%] sm:my-8 sm:w-full sm:max-w-lg ${
              isClosing ? "modalloginclose" : "modalloginopen"
            }`}
            onAnimationEnd={handleAnimationEnd}
            style={{
              backgroundColor: colors?.secondaryColorBG,
            }}
          >
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex items-center text-[#b1b6c6] justify-between">
                {ModeChange === "Login" ? (
                  <div>
                    <button
                      className="p-2 mr-2 transition-all duration-200 ease-in-out rounded-lg brightness-90 hover:brightness-90"
                      onClick={() => handleMode("Login")}
                      style={{
                        backgroundColor: colors?.mainColorBG,
                      }}
                    >
                      <span
                        className="font-semibold transition-all duration-200"
                        style={{
                          color: colors?.profitColor,
                          textShadow: `0px 0px 8px ${colors?.profitColor}`,
                        }}
                      >
                        LOGIN
                      </span>
                    </button>
                    <button
                      className="p-2 mr-2 transition-all duration-200 ease-in-out rounded-lg hover:brightness-90"
                      onClick={() => handleMode("Register")}
                      style={{
                        backgroundColor: colors?.mainColorBG,
                      }}
                    >
                      <span className="font-semibold ">REGISTER</span>
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="p-2 mr-2 transition-all duration-200 ease-in-out rounded-lg hover:brightness-90"
                      onClick={() => handleMode("Login")}
                      style={{
                        backgroundColor: colors?.mainColorBG,
                      }}
                    >
                      <span className="font-semibold ">LOGIN</span>
                    </button>
                    <button
                      className="p-2 mr-2 transition-all duration-200 ease-in-out rounded-lg brightness-90 hover:brightness-90"
                      onClick={() => handleMode("Register")}
                      style={{
                        backgroundColor: colors?.mainColorBG,
                      }}
                    >
                      <span
                        className="font-semibold transition-all duration-200 text-[#ffffc1]"
                        style={{
                          color: colors?.profitColor,
                          textShadow: `0px 0px 8px ${colors?.profitColor}`,
                        }}
                      >
                        REGISTER
                      </span>
                    </button>
                  </div>
                )}
                <div>
                  <button
                    className="text-xl"
                    onClick={() => handleOpenWithAnimation()}
                  >
                    <IoMdClose />
                  </button>
                </div>
              </div>
            </div>
            <div className="px-4 pb-4 sm:pb-4">
              <div className="sm:flex sm:items-start text-[#b1b6c6] justify-between">
                {ModeChange === "Login" ? (
                  <form className="w-full p-2" onSubmit={handleLoginSubmit}>
                    <div>
                      <p className="text-sm font-bold">
                        EMAIL<span className="text-red-500">*</span>
                      </p>
                      <div className="relative py-2">
                        <input
                          type="email"
                          id="email"
                          className="block w-full p-2 text-sm font-bold rounded-md ps-4 focus-visible:outline-none"
                          placeholder="yourmail@domain.com"
                          value={email}
                          onChange={handleEmail}
                          style={{
                            backgroundColor: colors?.mainColorBG,
                          }}
                        />
                      </div>
                      <p className="text-sm font-bold">
                        PASSWORD<span className="text-red-500">*</span>
                      </p>
                      <div className="relative py-2">
                        <input
                          type="password"
                          id="password"
                          className="block w-full p-2 text-sm font-extrabold rounded-md ps-4 focus-visible:outline-none"
                          placeholder="********"
                          value={password}
                          onChange={handlePassword}
                          style={{
                            backgroundColor: colors?.mainColorBG,
                          }}
                        />
                      </div>
                      <div className="pt-2">
                        <button
                          className={`w-full py-3 text-black text-sm uppercase font-bold transition-all duration-200 hover:brightness-75 rounded-lg`}
                          style={{
                            backgroundColor: colors?.secondaryButton?.mainColor,
                            boxShadow: `0px 0px 10px ${hexToRgbA(
                              colors?.secondaryButton?.shadowColor + "66"
                            )}, 0px 1px 0px ${hexToRgbA(
                              newShade(colors?.secondaryButton?.shadowColor, 40)
                            )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                              colors?.secondaryButton?.shadowColor + "66"
                            )} inset`,
                          }}
                        >
                          LOGIN
                        </button>
                      </div>
                      {errorMessage && (
                        <p className="flex justify-center text-red-500">
                          {errorMessage}
                        </p>
                      )}
                    </div>
                  </form>
                ) : (
                  <form className="w-full p-2" onSubmit={handleSignupSubmit}>
                    <p className="text-sm font-bold">
                      USERNAME<span className="text-red-500">*</span>
                    </p>
                    <div className="relative py-2">
                      <input
                        type="text"
                        id="username"
                        className="block w-full p-2 text-sm font-bold rounded-md ps-4 focus-visible:outline-none"
                        placeholder="Username"
                        value={usernameReg}
                        onChange={handleUsernameReg}
                        style={{
                          backgroundColor: colors?.mainColorBG,
                        }}
                      />
                    </div>
                    <p className="text-sm font-bold">
                      EMAIL<span className="text-red-500">*</span>
                    </p>
                    <div className="relative py-2">
                      <input
                        type="email"
                        id="email"
                        className="block w-full p-2 text-sm font-bold rounded-md ps-4 focus-visible:outline-none"
                        placeholder="yourmail@domain.com"
                        value={emailReg}
                        onChange={handleEmailReg}
                        style={{
                          backgroundColor: colors?.mainColorBG,
                        }}
                      />
                    </div>
                    <p className="text-sm font-bold">
                      PASSWORD<span className="text-red-500">*</span>
                    </p>
                    <div className="relative py-2">
                      <input
                        type="password"
                        id="password"
                        className="block w-full p-2 text-sm font-extrabold rounded-md ps-4 focus-visible:outline-none"
                        placeholder="********"
                        value={passwordReg}
                        onChange={handlePasswordReg}
                        style={{
                          backgroundColor: colors?.mainColorBG,
                        }}
                      />
                    </div>
                    <div className="pt-2">
                      <button
                        className={`w-full py-3 text-black text-sm uppercase font-bold transition-all duration-200 hover:brightness-75 rounded-lg`}
                        style={{
                          backgroundColor: colors?.secondaryButton?.mainColor,
                          boxShadow: `0px 0px 10px ${hexToRgbA(
                            colors?.secondaryButton?.shadowColor + "66"
                          )}, 0px 1px 0px ${hexToRgbA(
                            newShade(colors?.secondaryButton?.shadowColor, 40)
                          )} inset, 0px -3px 0px rgba(0,0,0,0.15) inset, 0px 0px 12px ${hexToRgbA(
                            colors?.secondaryButton?.shadowColor + "66"
                          )} inset`,
                        }}
                      >
                        START PLAYING
                      </button>
                    </div>
                    {errorMessageReg && (
                      <p className="flex justify-center text-red-500">
                        {errorMessageReg}
                      </p>
                    )}
                  </form>
                )}
              </div>
            </div>
            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"></div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
