import "./ProfilePage.css";

function ProfilePage() {
  return (
    <div>
      <h1>Profile page</h1>
    </div>
  );
}

export default ProfilePage;
