import React, { useState } from 'react';
import { FaCog } from "react-icons/fa";
import { useWebSocket } from '../../context/WebSocketProvider';

const GraphSettings = ({ isOpen, toggleOpen }) => {
  const { colors } = useWebSocket();

  // Initialize default values for localStorage if not set
  if (!localStorage.getItem('publicBets')) {
    localStorage.setItem('publicBets', JSON.stringify(true));
  }
  if (!localStorage.getItem('myBets')) {
    localStorage.setItem('myBets', JSON.stringify(true));
  }

  // Initialize state with the parsed values from localStorage
  const [publicBets, setPublicBets] = useState(JSON.parse(localStorage.getItem('publicBets')));
  const [myBets, setMyBets] = useState(JSON.parse(localStorage.getItem('myBets')));

  const handlePublicBetsChange = () => {
    const newValue = !publicBets;
    setPublicBets(newValue);
    localStorage.setItem('publicBets', JSON.stringify(newValue));
  };

  const handleMyBetsChange = () => {
    const newValue = !myBets;
    setMyBets(newValue);
    localStorage.setItem('myBets', JSON.stringify(newValue));
  }
  const newShade = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <>
      <div className='relative'>
        <button onClick={toggleOpen} className="p-2 rounded-md w-12 flex justify-center py-2.5 hover:brightness-125"
          style={{
            backgroundColor: colors?.secondaryColorBG,
          }}>
          <FaCog />
        </button>
        {isOpen && (
          <div className="absolute right-0 z-10 py-2 text-white transform translate-x-0 rounded shadow-lg top-12 " style={{
            backgroundColor: colors?.secondaryColorBG,
          }}>
            <div className='flex items-center px-4 py-2 gap-x-2'>
              <label className='flex items-center cursor-pointer select-none'>
                <div className='relative'>
                  <input
                    type='checkbox'
                    checked={myBets}
                    onChange={handleMyBetsChange}
                    className='sr-only'
                  />
                  <div className={`block w-10 h-5 rounded-full`}
                    style={{
                      backgroundColor: myBets ? colors?.profitColor : colors?.mainColorBG,
                    }}></div>
                  <div
                    className={`absolute left-1 top-1 w-3 h-3 rounded-full bg-white transition-transform ${myBets ? 'translate-x-[1.25rem]' : 'translate-x-0'}`}
                  ></div>
                </div>
              </label>
              <p className='text-sm text-[#b1b6c6] whitespace-nowrap font-medium'>Show My Bets on chart</p>
            </div>
            <div className='flex items-center justify-between px-4 py-2 gap-x-2'>
              <label className='flex items-center cursor-pointer select-none'>
                <div className='relative'>
                  <input
                    type='checkbox'
                    checked={publicBets}
                    onChange={handlePublicBetsChange}
                    className='sr-only'
                  />
                  <div className={`block w-10 h-5 rounded-full`}
                    style={{
                      backgroundColor: publicBets ? colors?.profitColor : colors?.mainColorBG,
                    }}></div>
                  <div
                    className={`absolute left-1 top-1 w-3 h-3 rounded-full bg-white transition-transform ${publicBets ? 'translate-x-[1.25rem]' : 'translate-x-0'}`}
                  ></div>
                </div>
              </label>
              <p className='text-sm text-[#b1b6c6] whitespace-nowrap font-medium'>Show Public Bets on chart</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GraphSettings;