import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { useWebSocket } from "../../context/WebSocketProvider";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import { AuthContext } from "../../context/auth.context";
import { FaSignOutAlt } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa";
import { useNotificationsUser } from "../../context/notificationsuser.context";
import Notifications from "../Navbar/NotificationMenu";
import axios from "axios";

function HamburguerMenu({ openHamburguer, setOpenHamburguer, open, setOpen, handleOpen, mode }) {
    const { isLoggedIn, user, logOutUser } = useContext(AuthContext);
    const { colors } = useWebSocket();
    const [isClosing, setIsClosing] = useState(false); // State to manage closing animation
    const { NotificationsUser, setNotificationsUser } = useNotificationsUser();
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    // Function to convert hex to RGBA
    const hexToRgbA = (hex) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/.test(hex)) {
            c = hex.substring(1).split("");
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2], "F", "F"]; // For short hex, default alpha to 'FF' (1.0)
            } else if (c.length === 6) {
                c.push("F", "F"); // If no alpha, default to 1.0
            }
            c = "0x" + c.join("");
            return (
                "rgba(" +
                [
                    (c >> 24) & 255,
                    (c >> 16) & 255,
                    (c >> 8) & 255,
                    (c & 255) / 255,
                ].join(",") +
                ")"
            );
        }
    };

    // Function to adjust color shade
    const newShade = (hexColor, magnitude) => {
        if (!hexColor) return;
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };
    const handleNotifications = () => {
        setNotificationsOpen(!notificationsOpen);
    };

    const handleClearNotifications = () => {
        if (NotificationsUser.length === 0) return;
        axios
            .patch(
                process.env.REACT_APP_SERVER_URL + `/user/notifications/clear`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }
            )
            .then(() => {
                setNotificationsUser([]);
            })
            .catch((error) => {
                console.error("Error clearing notifications:", error);
            });
    };

    // Handle opening with animation
    const handleOpenWithAnimation = () => {
        if (openHamburguer) {
            // If modal is open, initiate close animation
            setIsClosing(true);
        } else {
            setOpenHamburguer(true); // Directly open the modal
        }
    };

    // Handle end of animation
    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsClosing(false); // Reset closing state
            setOpenHamburguer(false); // Actually close the modal after animation
        }
    };

    return (
        <div className="fixed inset-0 z-10">
            <Dialog
                open={openHamburguer}
                className="md:hidden fixed inset-0 z-10 overflow-hidden"
                onClose={() => handleOpenWithAnimation()}
            >
                <DialogBackdrop
                    className={`fixed inset-0 transition-opacity ${isClosing ? "betbackdropclose overflow-x-hidden" : "betbackdropopen"
                        }`}
                    style={{
                        backgroundColor: colors?.mainColorBG + "90",
                    }}
                />
                <div className="fixed inset-0 z-10 flex items-center justify-end text-white">
                    <DialogPanel
                        className={`relative transform overflow-hidden text-left shadow-xl transition-transform sm:my-8 sm:w-full sm:max-w-lg h-full ${isClosing ? "hamburguermenuclose overflow-x-hidden" : "hamburguermenuopen"
                            }`}
                        onAnimationEnd={handleAnimationEnd}
                        style={{
                            backgroundColor: colors?.secondaryColorBG,
                            width: '70vw',
                        }}
                    >
                        <button className="p-4 text-white text-xl" onClick={() => handleOpenWithAnimation()}><IoMdClose /> </button>
                        {isLoggedIn && (
                            <div>
                                <div className="flex items-center justify-center gap-x-2">
                                    <img
                                        src={`/images/avatar/${user?.avatar}`}
                                        alt="avatar"
                                        className="w-[30px]"
                                    />
                                    <div className="flex flex-col items-start mr-6">
                                        <span>{user && user.username}</span>
                                        <span
                                            className="jaugeuser"
                                            style={{
                                                background: `linear-gradient(90deg, ${colors?.firstColor} 0%, ${colors?.secondColor} 33%, ${colors?.thirdColor}  66%, ${colors?.fourthColor} 100%)`,
                                            }}
                                        >
                                            <span className="jaugeempty"></span>
                                        </span>
                                    </div>
                                    <div className="relative flex items-center">
                                        <button className="relative text-[#B1B6C6]" onClick={() => handleNotifications()}>
                                            <FaRegBell />
                                            {NotificationsUser.length !== 0 ? (
                                                <span className="block float-right mt-[-20px] mr-[-2px] rounded-full h-1 w-1 bg-red-500"></span>
                                            ) : null}
                                        </button>
                                        <div className="absolute -left-[218px]">
                                            <Notifications notificationsOpen={notificationsOpen} handleNotifications={handleNotifications} handleClearNotifications={handleClearNotifications} NotificationsUser={NotificationsUser} colors={colors} />
                                        </div>
                                    </div>
                                </div>
                                <button onClick={logOutUser} className="text-[#B1B6C6] w-full justify-center flex p-4">
                                    <FaSignOutAlt />
                                </button>
                            </div>
                        )}
                    </DialogPanel>
                </div>
            </Dialog>
        </div>
    );
}

export default HamburguerMenu;
