// src/components/AnimatedNumber.jsx
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const AnimatedNumber = ({ value, format, duration = 300 }) => {
    const [displayValue, setDisplayValue] = useState(value);
    const prevValueRef = useRef(value);
    const animationRef = useRef();

    const getFormattedValue = (val) => {
        return format ? format(val) : val.toFixed(2);
    };

    const animateNumber = (from, to, duration) => {
        const startTime = performance.now();

        const step = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1); // Ensure progress does not exceed 1
            const newVal = from + (to - from) * progress;
            setDisplayValue(getFormattedValue(newVal));

            if (progress < 1) {
                animationRef.current = requestAnimationFrame(step);
            }
        };

        animationRef.current = requestAnimationFrame(step);
    };

    useEffect(() => {
        const prevValue = prevValueRef.current;
        const newValue = value;

        // Animate only if the value has changed
        if (prevValue !== newValue) {
            animateNumber(prevValue, newValue, duration);
            prevValueRef.current = newValue;
        }

        // Cleanup function to cancel any ongoing animation
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [value, duration, format]);

    // Initialize the displayValue on mount
    useEffect(() => {
        setDisplayValue(getFormattedValue(value));
        prevValueRef.current = value;
    }, []); // Empty dependency array ensures this runs once on mount

    return <span>{displayValue}</span>;
};

AnimatedNumber.propTypes = {
    value: PropTypes.number.isRequired,
    format: PropTypes.func,
    duration: PropTypes.number,
};

export default AnimatedNumber;
