import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './auth.context';
import { useWebSocket } from './WebSocketProvider'; // Import the WebSocket context

const BalanceUserContext = createContext(null);

export const BalanceUserProvider = ({ children }) => {
    const [BalanceUser, setBalanceUser] = useState(0);
    const { isLoggedIn, user } = useContext(AuthContext);  // Access authentication context
    const { balanceUpdated } = useWebSocket();  // Access WebSocket context

    // Function to fetch balance from the server
    const getBalance = async () => {
        if (!isLoggedIn || !user) return;

        await axios
            .get(`${process.env.REACT_APP_SERVER_URL}/user/balance`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            })
            .then((res) => {
                setBalanceUser(res.data.balance);
            })
            .catch((err) => {
                console.error('Error fetching balance:', err);
            });
    };

    // Update balance based on WebSocket messages
    useEffect(() => {
        if (isLoggedIn && user) {
            // Call getBalance when the component mounts or user logs in
            getBalance();
        }
    }, [balanceUpdated, user]);  // Rerun when bets or user status change

    return (
        <BalanceUserContext.Provider value={{ BalanceUser, setBalanceUser }}>
            {children}
        </BalanceUserContext.Provider>
    );
};

export const useBalanceUser = () => useContext(BalanceUserContext);
