import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
const successSound = new Audio('/sounds/success.mp3');
const errorSound = new Audio('/sounds/error.mp3');

successSound.preload = 'auto';
errorSound.preload = 'auto';

successSound.volume = 0.25;
errorSound.volume = 0.25;

export const notify = (content, type = 'success', colors, sound) => {
    // Play the corresponding sound
    if (sound === true && type === 'success') {
        successSound.play().catch(error => {
            console.error("Error playing success sound:", error);
        });
    } else if (sound === true && type === 'error') {
        errorSound.play().catch(error => {
            console.error("Error playing error sound:", error);
        });
    }

    toast(content, {
        className: `toast-with-bar`,
        style: {
            backgroundColor: colors.mainColorBG,
            borderLeft: `5px solid ${type === 'success' ? colors.profitColor : colors.lossColor}`,
        },
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const notifyBetPlaced = (bet, colors) => {
    const content = (
        <div className="toast-content">
            <div className='relative'>
                <img
                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`}
                    alt={bet.coin}
                    className="toast-image"
                />
                <span className='absolute text-center rounded-full -top-1 right-1.5 p-0.5' style={{
                    backgroundColor: colors.mainColorBG,
                }}>{bet.direction === 'up' ? <FaAngleDoubleUp className=' size-3' style={{
                    color: colors.profitColor
                }} /> : <FaAngleDoubleDown className='size-3' style={{
                    color: colors.lossColor
                }} />}</span>
            </div>
            <div className="toast-text">
                <div className="toast-title">Bet placed</div>
                <div className="toast-details">
                    Wager: {bet.amount}, multiplier: x{bet.multiplier}, bust price: {bet.bustPrice}
                </div>
            </div>
        </div>
    );
    notify(content, 'success', colors, true);
};

export const notifyBetBusted = (bet, colors) => {
    const content = (
        <div className="toast-content">
            <div className='relative'>
                <img
                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`}
                    alt={bet.coin}
                    className="toast-image"
                />
                <span className='absolute text-center rounded-full -top-1 right-1.5 p-0.5' style={{
                    backgroundColor: colors.mainColorBG,
                }}>{bet.direction === 'up' ? <FaAngleDoubleUp className=' size-3' style={{
                    color: colors.profitColor
                }} /> : <FaAngleDoubleDown className='size-3' style={{
                    color: colors.lossColor
                }} />}</span>
            </div>
            <div className="toast-text">
                <div className="toast-title">Your position has been busted</div>
                <div className="toast-details">
                    Wager: {bet.amount}, multiplier: x{bet.multiplier}, bust price: {bet.bustPrice}
                </div>
            </div>
        </div>
    );
    notify(content, 'error', colors, true);
};

export const notifyBetCashedOut = (bet, colors) => {
    const content = (
        <div className="toast-content">
            <div className='relative'>
                <img
                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`}
                    alt={bet.coin}
                    className="toast-image"
                />
                <span className='absolute text-center rounded-full -top-1 right-1.5 p-0.5' style={{
                    backgroundColor: colors.mainColorBG,
                }}>{bet.direction === 'up' ? <FaAngleDoubleUp className=' size-3' style={{
                    color: colors.profitColor
                }} /> : <FaAngleDoubleDown className='size-3' style={{
                    color: colors.lossColor
                }} />}</span>
            </div>
            <div className="toast-text">
                <div className="toast-title">Bet Cashed Out</div>
                <div className="toast-details">
                    Wager: {bet.amount}, multiplier: x{bet.multiplier}, bust price: {bet.bustPrice}
                </div>
            </div>
        </div>
    );
    notify(content, bet.result === 'win' ? 'success' : 'error', colors, true);
};