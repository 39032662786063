import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../context/WebSocketProvider";
import { SiBetfair } from "react-icons/si";
import { RiHome3Fill, RiRobot2Fill, RiUser3Fill, RiBitCoinFill, RiSettings2Fill, RiHome3Line, RiBitCoinLine, RiUser3Line, RiRobot2Line, RiSettings2Line } from "react-icons/ri";
import { PiPokerChipFill, PiPokerChipLight } from "react-icons/pi";
import { HiColorSwatch, HiOutlineColorSwatch } from "react-icons/hi";
const DashboardCasino = () => {
  const { colors } = useWebSocket();
  const navigate = useNavigate();

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };
  return (
    <div className="flex flex-row containerNavbar">
      <div
        className="w-[50px] xl:w-[285px] text-left text-white overflow-y-hidden flex-shrink-0"
        style={{
          backgroundColor: colors?.secondaryColorBG,
        }}
      >
        <ul className="xl:px-4">
          <p
            className="hidden p-4 text-sm tracking-widest text-left uppercase  xl:block"
            style={{
              color: newShade(colors?.secondaryColorBG, 120),
            }}
          >
            General
          </p>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 60)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/" ? <RiHome3Fill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/" ? colors?.profitColor : "",
            }} /> : <RiHome3Line className="w-5 h-5 mx-2" />} Dashboard
          </li>
          <li
            className={`p-4 cursor-pointer justify-center flex xl:hidden`}
            onClick={() => navigate("/casino/dashboard/")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/" ? <RiHome3Fill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/" ? colors?.profitColor : "",
            }} /> : <RiHome3Line className="w-5 h-5" />}
          </li>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/users")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/users"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/users"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/users" ? <RiUser3Fill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/users" ? colors?.profitColor : "",
            }} /> : <RiUser3Line className="w-5 h-5 mx-2" />} Users
          </li>
          <li
            className={`p-4 cursor-pointer justify-center flex xl:hidden`}
            onClick={() => navigate("/casino/dashboard/users")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/users"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/users" ? <RiUser3Fill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/users" ? colors?.profitColor : "",
            }} /> : <RiUser3Line className="w-5 h-5" />}
          </li>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/bets")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/bets"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",

              border:
                window.location.pathname === "/casino/dashboard/bets"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/bets" ? <PiPokerChipFill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/bets" ? colors?.profitColor : "",
            }} /> : <PiPokerChipLight className="w-5 h-5 mx-2" />}Bets
          </li>

          <li
            className={`p-4 cursor-pointer justify-center flex xl:hidden`}
            onClick={() => navigate("/casino/dashboard/bets")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/bets"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/bets" ? <PiPokerChipFill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/bets" ? colors?.profitColor : "",
            }} /> : <PiPokerChipLight className="w-5 h-5" />}
          </li>
          <p
            className="hidden p-4 text-sm tracking-widest text-left uppercase  xl:block"
            style={{
              color: newShade(colors?.secondaryColorBG, 120),
            }}
          >
            Management
          </p>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/crypto-coins")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/crypto-coins"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/crypto-coins"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/crypto-coins" ? <RiBitCoinFill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/crypto-coins" ? colors?.profitColor : "",
            }} /> : <RiBitCoinLine className="w-5 h-5 mx-2" />}Coins
          </li>
          <li
            className={`p-4 cursor-pointer justify-center flex xl:hidden`}
            onClick={() => navigate("/casino/dashboard/crypto-coins")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/crypto-coins"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/crypto-coins" ? <RiBitCoinFill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/crypto-coins" ? colors?.profitColor : "",
            }} /> : <RiBitCoinLine className="w-5 h-5" />}
          </li>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/bots")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/bots" ||
                  window.location.pathname === "/casino/dashboard/bots/create"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/bots" ||
                  window.location.pathname === "/casino/dashboard/bots/create"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/bots" || window.location.pathname === "/casino/dashboard/bots/create" ? <RiRobot2Fill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/bots" || window.location.pathname === "/casino/dashboard/bots/create" ? colors?.profitColor : "",
            }} /> : <RiRobot2Line className="w-5 h-5 mx-2" />} Bots
          </li>

          <li
            className={`p-4 cursor-pointer  justify-center flex xl:hidden`}
            onClick={() => navigate("/casino/dashboard/bots")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/bots" ||
                  window.location.pathname === "/casino/dashboard/bots/create"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/bots" || window.location.pathname === "/casino/dashboard/bots/create" ? <RiRobot2Fill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/bots" || window.location.pathname === "/casino/dashboard/bots/create" ? colors?.profitColor : "",
            }} /> : <RiRobot2Line className="w-5 h-5" />}
          </li>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/colors")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/colors" ||
                  window.location.pathname === "/casino/dashboard/colors/edit"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/colors" ||
                  window.location.pathname === "/casino/dashboard/colors/edit"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/colors" || window.location.pathname === "/casino/dashboard/colors/edit" ? <HiColorSwatch className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/colors" || window.location.pathname === "casino/dashboard/colors/edit" ? colors?.profitColor : "",
            }} /> : <HiOutlineColorSwatch className="w-5 h-5 mx-2" />}Colors
          </li>

          <li
            className={`p-4 cursor-pointer flex justify-center xl:hidden`}
            onClick={() => navigate("/casino/dashboard/colors")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/colors" ||
                  window.location.pathname === "/casino/dashboard/colors/edit"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/colors" || window.location.pathname === "/casino/dashboard/colors/edit" ? <HiColorSwatch className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/colors" || window.location.pathname === "casino/dashboard/colors/edit" ? colors?.profitColor : "",
            }} /> : <HiOutlineColorSwatch className="w-5 h-5" />}
          </li>
          <li
            className={`p-2 my-2 rounded-md cursor-pointer hidden xl:flex items-center`}
            onClick={() => navigate("/casino/dashboard/settings")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/settings"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
              border:
                window.location.pathname === "/casino/dashboard/settings"
                  ? `1px solid ${newShade(colors?.secondaryColorBG, 40)}`
                  : "1px solid transparent",
            }}
          >
            {window.location.pathname === "/casino/dashboard/settings" ? <RiSettings2Fill className="w-5 h-5 mx-2" style={{
              color: window.location.pathname === "/casino/dashboard/settings" ? colors?.profitColor : "",
            }} /> : <RiSettings2Line className="w-5 h-5 mx-2" />} Settings
          </li>
          <li
            className={`p-4 cursor-pointer flex justify-center xl:hidden`}
            onClick={() => navigate("/casino/dashboard/settings")}
            style={{
              backgroundColor:
                window.location.pathname === "/casino/dashboard/settings"
                  ? newShade(colors?.secondaryColorBG, 20)
                  : "",
            }}
          >
            {window.location.pathname === "/casino/dashboard/settings" ? <RiSettings2Fill className="w-5 h-5" style={{
              color: window.location.pathname === "/casino/dashboard/settings" ? colors?.profitColor : "",
            }} /> : <RiSettings2Line className="w-5 h-5" />}
          </li>
        </ul>
      </div>
    </div >
  );
};

export default DashboardCasino;
