import CryptoJS from 'crypto-js';

const keyHex = process.env.REACT_APP_ENCRYPTION_KEY;
if (!keyHex) {
    throw new Error('REACT_APP_ENCRYPTION_KEY is not set in environment variables');
}
const key = CryptoJS.enc.Hex.parse(keyHex);

export const encrypt = (text) => {
    const iv = CryptoJS.lib.WordArray.random(16); // 128-bit IV
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
    return {
        iv: iv.toString(CryptoJS.enc.Hex),
        encryptedData: encrypted.toString() // CryptoJS outputs Base64 by default
    };
};

export const decrypt = (encrypted) => {
    const iv = CryptoJS.enc.Hex.parse(encrypted.iv);
    const decrypted = CryptoJS.AES.decrypt(encrypted.encryptedData, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
};