import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardCasino from "./DashboardMenu";
import { useWebSocket } from "../../context/WebSocketProvider";

const CasinoSettings = () => {
  const [settings, setSettings] = useState({});
  const { colors } = useWebSocket();

  useEffect(() => {
    const getSettings = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/settings/Settings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setSettings(response.data);
    };
    getSettings();
  }, []);

  const handleCheckboxChange = () => {
    axios
      .patch(
        process.env.REACT_APP_SERVER_URL + `/settings/GenBalance`,
        {
          GenBalance: !settings.GenBalance,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setSettings({ ...settings, GenBalance: response.data.GenBalance });
      });
  };

  const newShade = (hexColor, magnitude) => {
    if (!hexColor || typeof hexColor !== "string") {
      console.error("Invalid hexColor provided:", hexColor);
      return "#000000"; // Return a default color or handle the error as needed
    }

    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <div className="flex flex-row containerNavbar"
      style={{
        backgroundColor: colors?.mainColorBG,
      }}>
      <DashboardCasino />
      <div className="flex flex-col w-full p-8 pt-4">
        <h1 className="pb-5 text-4xl text-left font-bold text-white">Settings</h1>
        <div className="flex flex-col items-center w-full">
          {settings && (
            <div
              className="flex items-center p-4 text-center rounded-lg shadow-md gap-x-2"
              style={{
                backgroundColor: newShade(colors?.secondaryColorBG, 10),
                border: `1px solid ${newShade(colors?.secondaryColorBG, 25)}`,
              }}
            >
              <div className="flex p-2 rounded-lg shadow-md items-center gap-x-2"
                style={{
                  backgroundColor: newShade(colors?.secondaryColorBG, 30),
                  border: `1px solid ${newShade(colors?.secondaryColorBG, 60)}`,
                }}>
                <p className="text-base font-semibold text-white">
                  Generate Balance
                </p>
                <label className="flex items-center cursor-pointer select-none">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={settings.GenBalance}
                      onChange={() => handleCheckboxChange()}
                      className="sr-only"
                    />
                    <div
                      className={`block ${settings.GenBalance ? "bg-green-500" : "bg-[#1a1d29]"
                        } w-14 h-8 rounded-full`}
                      style={{
                        backgroundColor: settings.GenBalance
                          ? colors?.profitColor
                          : colors?.secondaryColorBG,
                      }}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 w-6 h-6 rounded-full bg-white transition-transform ${settings.GenBalance
                        ? "transform translate-x-full"
                        : "transform translate-x-0"
                        }`}
                    ></div>
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CasinoSettings;
