import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from "../context/auth.context";
import { notifyBetBusted, notifyBetCashedOut, notifyBetPlaced } from "../components/Toast/Toast";
import { useBetContext } from "../context/bet.context";
import axios from 'axios';
import { useNotificationsUser } from './notificationsuser.context';
import CryptoJS from 'crypto-js';
import { encrypt as clientEncrypt, decrypt as clientDecrypt } from '../components/SymmetricEncryptionClient/SymmetricEncryptionClient';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [cryptoData, setCryptoData] = useState([]);
    const [statistics, setStatistics] = useState({});
    const [publicBets, setPublicBets] = useState([]);
    const [closedBets, setClosedBets] = useState([]);
    const [activeBets, setActiveBets] = useState([]);
    const [cryptoInfo, setCryptoInfo] = useState([]);
    const [currentOHLC, setCurrentOHLC] = useState([]);
    const [balanceUpdated, setBalanceUpdated] = useState(null);
    const [error, setError] = useState(null);
    const wsRef = useRef(null);
    const reconnectRef = useRef(null);
    const { selectedBet, setSelectedBet } = useBetContext();
    const { user, isLoggedIn } = useContext(AuthContext);
    const userRef = useRef(user);
    const selectedBetRef = useRef(selectedBet);
    const [colors, setColors] = useState({});
    const colorsRef = useRef({});
    const { NotificationsUser, setNotificationsUser } = useNotificationsUser();

    useEffect(() => {
        userRef.current = user;
    }, [user?._id]);

    useEffect(() => {
        selectedBetRef.current = selectedBet;
    }, [selectedBet]);

    // Fetch user bets when the user logs in
    useEffect(() => {
        const fetchUserBets = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/bet/userBets/${user?._id}?t=${new Date().getTime()}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                });

                if (response.status === 200) {
                    const { activeBets, closedBets } = response.data;

                    setActiveBets(activeBets);
                    setClosedBets(closedBets);
                }
            } catch (error) {
                console.error('Error fetching user bets:', error);
            }
        };

        if (user && user._id) {
            fetchUserBets();
        } else {
            // Clear bets if the user logs out
            setActiveBets([]);
            setClosedBets([]);
        }
    }, [user?._id]);

    const sortBets = (bets) => {
        return bets.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    };

    const sortActiveBets = (bets) => {
        return bets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    };

    const handleNewBet = (newBet) => {
        if (newBet.userId === (userRef.current && userRef.current._id)) {
            setActiveBets(prevBets => {
                const updatedBets = sortActiveBets([newBet, ...prevBets.filter(bet => bet._id !== newBet._id)]);
                return updatedBets;
            });
        }
    };

    const handleCashedOutBet = (updatedBet) => {
        if (updatedBet.userId === (userRef.current && userRef.current._id)) {
            setBalanceUpdated(updatedBet);
            setActiveBets(prevBets => {
                const remainingActiveBets = prevBets.filter(bet => bet._id !== updatedBet._id);
                return sortActiveBets(remainingActiveBets);
            });

            setClosedBets(prevBets => sortBets([updatedBet, ...prevBets]));
            setPublicBets(prevBets => {
                const updatedBets = sortBets([
                    updatedBet,
                    ...prevBets.filter(bet => bet._id !== updatedBet._id)
                ]).slice(0, 15); // Keep only the first 15 bets
                return updatedBets;
            });
        } else {
            setPublicBets(prevBets => {
                const updatedBets = sortBets([
                    updatedBet,
                    ...prevBets.filter(bet => bet._id !== updatedBet._id)
                ]).slice(0, 15); // Keep only the first 15 bets
                return updatedBets;
            });
        }
    };

    const wsMessage = (message) => {
        const randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const newNotification = {
            ...message.data, id: randomId
        }
        const wsMessage = {
            type: 'notification',
            userId: message.data.userId,
            notifications: newNotification
        };
        setNotificationsUser((prevNotifications) => [
            wsMessage.notifications,
            ...prevNotifications
        ]);
        sendMessage(wsMessage);
    };


    const connectWebSocket = () => {
        if (wsRef.current) {
            wsRef.current.close(); // Close any existing WebSocket connection
            wsRef.current = null;
        }

        const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
        wsRef.current = ws;

        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
            const encryptedData = JSON.parse(event.data);
            const decryptedMessage = clientDecrypt(encryptedData);
            const message = JSON.parse(decryptedMessage);

            if (document.visibilityState === 'visible') {
                if (message.type === 'cryptoData') {
                    setCryptoData(message.data);
                } else if (message.type === 'statistics') {
                    setStatistics(message.data);
                } else if (message.type === 'newBet') {
                    if (message.data.result === 'pending' && message.data.userId === (userRef.current && userRef.current._id)) {
                        setBalanceUpdated(message.data)
                        notifyBetPlaced(message.data, colorsRef.current);
                        wsMessage(message);
                    }
                    handleNewBet(message.data);
                } else if (message.type === 'cashedOutBet' || message.type === 'bustedBet') {
                    handleCashedOutBet(message.data);
                    if (message.data.result === 'busted' && message.data.userId === (userRef.current && userRef.current._id)) {
                        if (selectedBetRef?.current?._id === message?.data?._id) setSelectedBet(null);
                        notifyBetBusted(message.data, colorsRef.current);
                        wsMessage(message);
                    }
                    if (message.type === 'cashedOutBet' && message.data.userId === (userRef.current && userRef.current._id)) {
                        notifyBetCashedOut(message.data, colorsRef.current);
                        wsMessage(message);
                    }
                }
            }

            if (message.type === 'ActiveCoins') {
                setCryptoInfo(message.data);
            } else if (message.type === 'colors') {
                setColors(message.data);
                colorsRef.current = message.data;
            } else if (message.type === 'initial') {
                setCryptoInfo(message.info);
                const allBets = message.data.reverse();
                const closedBets = allBets.filter(bet => bet.result !== 'pending');
                setPublicBets(sortBets(closedBets));
            } else if (message.type === 'colorUpdate') {
                setColors(message.data);
            } else if (message.type === 'currentOHLC') {
                setCurrentOHLC(message.data);
            } else if (message.type === 'balanceUpdated') {
                setBalanceUpdated(message.data);
            }
        }

        ws.onerror = (error) => {
            console.error("WebSocket Error", error);
            setError(error);
        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
            reconnectRef.current = setTimeout(() => connectWebSocket(), 5000);  // Reconnect after 5 seconds
        };
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            if (reconnectRef.current) {
                clearTimeout(reconnectRef.current);
                reconnectRef.current = null;
            }
        };
    }, []);

    const sendMessage = (message) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            const { iv, encryptedData } = clientEncrypt(JSON.stringify(message));
            wsRef.current.send(JSON.stringify({ iv, encryptedData }));
        } else {
            console.warn('WebSocket is not open. Unable to send message.');
        }
    };

    return (
        <WebSocketContext.Provider value={{ cryptoData, statistics, publicBets, closedBets, activeBets, error, sendMessage, cryptoInfo, colors, currentOHLC, setCurrentOHLC, balanceUpdated }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
